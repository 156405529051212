export const TEXT_VALIDATION_ERROR_MESSAGES = {
  mustBeDate: 'Value must be a valid date',
  mustBeString: 'Value must be string',
  mustContainNoSpaces: 'Value must not contain spaces',
  mustBeEmail: 'Value must be a valid email',
  mustBeNumber: 'Value must be a number',
  mustBeTrue: 'Value must be a true',
  mustBeGreaterThan: 'Value must be greater than',
  mustBeLessThan: 'Value must be less than',
  shouldBeIBANFormat: 'Value should start from two capital letters',
  noFutureDate: 'Future date not allowed',
  mustBeIban: 'Value must be valid IBAN',
  mustBePdf: 'File must be pdf',
  inputMissing: 'validator missing in input field',
  required: 'Value is required',
}
