import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'

import { CardNotPresentForm } from './CardNotPresentForm'
import { CardNotPresentHeader } from './CardNotPresentHeader'

export const CardNotPresent: FunctionComponent = () => (
  <>
    <CardNotPresentHeader />
    <Box my={3}>
      <CardNotPresentForm />
    </Box>
  </>
)
