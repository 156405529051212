import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useForm } from 'react-final-form'

import { AccountType, AuthorisedPushPaymentFormFieldsKeys } from 'utils'
import {
  getFieldNameByAccountType,
  getSelectedAccountTypeValidators,
} from 'components/forms/commonFields/helpers'
import { composeValidators, exactValueLength, mustBeNumber } from 'utils/formValidators'
import { TextInput } from 'components/forms/commonFields'

export const TEXT_ACCOUNT_NUMBER_FIELD = {
  [AuthorisedPushPaymentFormFieldsKeys.IBAN]: 'Account IBAN',
  [AuthorisedPushPaymentFormFieldsKeys.AccountNumber]: 'Account Number',
  [AuthorisedPushPaymentFormFieldsKeys.SortCode]: 'Sort Code',
}

export const AccountNumberField = () => {
  const { getFieldState } = useForm()
  const accountTypeFieldState = getFieldState(
    AuthorisedPushPaymentFormFieldsKeys.AccountType,
  )

  if (!accountTypeFieldState?.value) return null

  const { value: selectedAccountType } = accountTypeFieldState
  const fieldName = getFieldNameByAccountType(selectedAccountType)
  const placeholder = TEXT_ACCOUNT_NUMBER_FIELD[fieldName]
  const isSCANSelected = selectedAccountType === AccountType.SCAN

  return (
    <Box marginTop={2} {...(isSCANSelected && { display: 'flex' })}>
      {isSCANSelected && (
        <Box mr={2}>
          <TextInput
            validate={composeValidators(mustBeNumber, exactValueLength(6))}
            name={AuthorisedPushPaymentFormFieldsKeys.SortCode}
            placeholder={
              TEXT_ACCOUNT_NUMBER_FIELD[AuthorisedPushPaymentFormFieldsKeys.SortCode]
            }
          />
        </Box>
      )}

      <Grid item sm={6}>
        <TextInput
          fullWidth
          name={fieldName}
          placeholder={placeholder}
          validate={getSelectedAccountTypeValidators(selectedAccountType)}
        />
      </Grid>
    </Box>
  )
}
