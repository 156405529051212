import React from 'react'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'

import {
  AuthorisedPushPaymentFormFieldTransactionKeys,
  getCurrentDate,
  isIEBrowser,
} from 'utils'
import {
  composeValidators,
  mustBeDateInput,
  mustBeNoFutureDate,
  requiredField,
} from 'utils/formValidators'

import { TextInput } from '../TextInput'
import { TransactionDateFieldFallBack } from '../TransactionDateFieldFallBack'
import { useTransactionContext } from './TransactionContext'

export const TEXT_DATE_TIME = {
  label: 'Date and time',
}

const currentIsoDateTime = format(getCurrentDate(), "yyyy-MM-dd'T'HH:mm")

export const TransactionDateField = () => {
  const { field } = useTransactionContext()
  const fieldName = `${field}.${AuthorisedPushPaymentFormFieldTransactionKeys.Timestamp}`

  return (
    <Grid item sm={8}>
      {isIEBrowser() ? (
        <TransactionDateFieldFallBack
          initialValue={currentIsoDateTime}
          name={fieldName}
          label={TEXT_DATE_TIME.label}
        />
      ) : (
        <TextInput
          fullWidth
          initialValue={currentIsoDateTime}
          name={fieldName}
          type="datetime-local"
          validate={composeValidators(
            requiredField,
            mustBeDateInput,
            mustBeNoFutureDate(currentIsoDateTime),
          )}
          label={TEXT_DATE_TIME.label}
        />
      )}
    </Grid>
  )
}
