import React from 'react'
import { useField } from 'react-final-form'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { AccountType } from 'utils'
import { AuthorisedPushPaymentFormFieldsKeys } from 'utils/types'

export const TEXT_ACCOUNT_TYPE = {
  [AccountType.IBAN]: 'IBAN',
  [AccountType.SCAN]: 'Sort Code & Account Number',
  [AccountType.WalletRef]: 'Reference Number',
  [AccountType.CardNumber]: 'Сard Number',
}

type AccountTypeFieldProps = {
  label?: string
  exclude?: ReadonlyArray<AccountType>
}

export const AccountTypeField = ({ label, exclude }: AccountTypeFieldProps) => {
  const { input } = useField(AuthorisedPushPaymentFormFieldsKeys.AccountType, {
    type: 'Radio',
    defaultValue: AccountType.IBAN,
  })

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Box my={2}>
          <strong>{label}</strong>
        </Box>
      </FormLabel>
      <RadioGroup {...input}>
        {Object.keys(AccountType)
          .filter(key => !exclude?.includes(AccountType[key]))
          .map(key => (
            <FormControlLabel
              key={key}
              value={AccountType[key]}
              control={<Radio />}
              label={TEXT_ACCOUNT_TYPE[AccountType[key]]}
            />
          ))}
      </RadioGroup>
    </FormControl>
  )
}
