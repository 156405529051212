import React from 'react'
import TreeView from '@material-ui/lab/TreeView'
import styled from 'styled-components'

import {
  IndeterminateCheckBoxOutlined,
  CancelPresentationOutlined,
  AddBoxOutlined,
} from '@material-ui/icons'
import { Box, Grid, Typography } from '@material-ui/core'

import { Preloader } from 'components'
import { useManagePartners } from '../ManagePartnersContext'
import { PartnerCard } from './PartnerCard/PartnerCard'

const StyledTreeView = styled(TreeView)`
  width: 100%;
  margin-top: 1.5rem !important;
`

export const TEXT_PARTNERS_LIST = {
  preloaderTitle: 'loading partners list',
  heading: 'Approved Partners',
}

export const PartnersList = (): JSX.Element => {
  const { loading, partners } = useManagePartners()

  if (loading) {
    return (
      <Grid item xs={12}>
        <Preloader
          wrapper={{ height: '10rem', mt: 5, title: TEXT_PARTNERS_LIST.preloaderTitle }}
        />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Box my={3}>
        <Typography variant="h6">{TEXT_PARTNERS_LIST.heading}</Typography>
      </Box>
      <StyledTreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={<AddBoxOutlined />}
        defaultEndIcon={<CancelPresentationOutlined />}
      >
        {partners.map(partnerItem => (
          <PartnerCard key={partnerItem.id} {...partnerItem} />
        ))}
      </StyledTreeView>
    </Grid>
  )
}
