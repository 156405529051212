import React, { createContext, FunctionComponent, useContext } from 'react'
import { useBoolean, UseBooleanActions } from 'react-hanger/array'

import { PartnerItem } from 'utils'

type EditContextType = {
  isEdit: Boolean
  editActions: UseBooleanActions
}

export const editContext = createContext<EditContextType>({} as EditContextType)
export const useEditContext = () => useContext<EditContextType>(editContext)

export const PartnerCardEditContext: FunctionComponent<{
  defaultValues: PartnerItem
}> = ({ children }) => {
  const [isEdit, editActions] = useBoolean(false)

  return (
    <editContext.Provider value={{ isEdit, editActions }}>
      {children}
    </editContext.Provider>
  )
}
