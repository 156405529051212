import { flow } from 'lodash/fp'
import { isValidIBAN } from 'ibantools'

import { FileTypes } from 'utils/types'
import { validatorsReducer } from '../validatorsReducer'
import { getFileFromInputRef } from '../getFileFromInputRef'
import { TEXT_VALIDATION_ERROR_MESSAGES } from './textMessages'

export type ValidatorReturnType = string | undefined
export type FileValidator = (param?: File) => ValidatorReturnType

export const mustBeEmail = (value: string): ValidatorReturnType =>
  /\S+@\S+\.\S+/i.test(value) ? undefined : TEXT_VALIDATION_ERROR_MESSAGES.mustBeEmail

export const mustBeDateInput = (value: string): ValidatorReturnType =>
  /\d\d-\d\d+-\d+T\d+:\d+/.test(value)
    ? undefined
    : `${TEXT_VALIDATION_ERROR_MESSAGES.mustBeDate} ex: 2020-09-23T13:22`

export const mustBeNoFutureDate =
  (initialDateValue: string) => (selectedDateValue: string) => {
    const initialDate = new Date(initialDateValue).getTime()
    const selectedDate = new Date(selectedDateValue).getTime()

    return selectedDate <= initialDate
      ? undefined
      : TEXT_VALIDATION_ERROR_MESSAGES.noFutureDate
  }

export const requiredField = <DataType = string>(value: DataType): ValidatorReturnType =>
  value ? undefined : TEXT_VALIDATION_ERROR_MESSAGES.required

export const mustBeNumber = (value: string): ValidatorReturnType =>
  !Number.isNaN(Number(value)) ? undefined : TEXT_VALIDATION_ERROR_MESSAGES.mustBeNumber

export const mustBeTrue = (value: string | boolean): ValidatorReturnType =>
  !value ? TEXT_VALIDATION_ERROR_MESSAGES.mustBeTrue : undefined

export const mustBeLettersOnly = (value: string): ValidatorReturnType =>
  /^\D+$/.test(value) ? undefined : TEXT_VALIDATION_ERROR_MESSAGES.mustBeString

export const mustContainNoSpaces = (value: string): ValidatorReturnType =>
  /\s/.test(value) ? TEXT_VALIDATION_ERROR_MESSAGES.mustContainNoSpaces : undefined

export const minValue =
  (min: number) =>
  (value: string): ValidatorReturnType => {
    const fieldValue = Number(value)

    if (Number.isNaN(fieldValue)) return TEXT_VALIDATION_ERROR_MESSAGES.mustBeNumber

    return fieldValue >= min
      ? undefined
      : `${TEXT_VALIDATION_ERROR_MESSAGES.mustBeGreaterThan} ${min}`
  }

export const startWithIBANCode = (value: string): ValidatorReturnType =>
  /^[A-Z][A-Z]/.test(value)
    ? undefined
    : TEXT_VALIDATION_ERROR_MESSAGES.shouldBeIBANFormat

export const mustBeValidIBANCode = (value: string) => {
  const pattern = /^\d{2}-\d{4}-\d{7}-\d{2,3}$/;
  
  const isValidIban = isValidIBAN(value)
  const isValidNzIban = pattern.test(value)

  if (isValidIban || isValidNzIban) {
      return undefined;
  };
  
  return TEXT_VALIDATION_ERROR_MESSAGES.mustBeIban
};

export const maxValue =
  (max: number) =>
  (value: string): ValidatorReturnType => {
    if (Number.isNaN(Number(value))) return TEXT_VALIDATION_ERROR_MESSAGES.mustBeNumber

    return Number(value) <= max
      ? undefined
      : `${TEXT_VALIDATION_ERROR_MESSAGES.mustBeLessThan} ${max}`
  }

export const maxValueLength =
  (num: number) =>
  (value: string): ValidatorReturnType =>
    value.length <= num
      ? undefined
      : `${TEXT_VALIDATION_ERROR_MESSAGES.mustBeLessThan} ${num} symbols`

export const exactValueLength =
  (num: number) =>
  (value: string): ValidatorReturnType =>
    value.length === num
      ? undefined
      : `Value should be ${num} symbols but ${value.length} provided`

export const maxFileSize = (sizeKb: number) => (file?: File) =>
  !requiredField<typeof file>(file) && file && file?.size > sizeKb * 1024
    ? `File size must be ${sizeKb} Kb or less`
    : undefined

export const mustBePdf: FileValidator = file =>
  !requiredField<typeof file>(file) && file?.type === FileTypes.Pdf
    ? undefined
    : TEXT_VALIDATION_ERROR_MESSAGES.mustBePdf

export const composeValidators = flow(validatorsReducer)

export const composeFileValidators = flow(validatorsReducer, validateFunction =>
  flow(getFileFromInputRef, validateFunction),
)
