import React from 'react'
import { Box, BoxProps, TableCell, TableSortLabel } from '@material-ui/core'

import { UserReport, EnhancedTableProps, HeadCell } from './types'

type TableHeaderCellProps = Pick<
  EnhancedTableProps<unknown>,
  'onRequestSort' | 'order' | 'orderBy'
> &
  HeadCell & {
    boxProps?: BoxProps
  }

export const TableHeaderCell = ({
  id,
  label,
  orderBy,
  order,
  onRequestSort,
  boxProps,
}: TableHeaderCellProps): JSX.Element => {
  const createSortHandler =
    (property: keyof UserReport) => (event: React.MouseEvent<unknown>) =>
      onRequestSort(event, property)

  return (
    <TableCell key={id} sortDirection={orderBy === id ? order : false}>
      <TableSortLabel
        title={`"${label}" ${
          order === 'desc' ? 'sorted descending' : 'sorted ascending'
        }`}
        active={orderBy === id}
        direction={orderBy === id ? order : 'asc'}
        onClick={createSortHandler(id)}
      >
        <Box {...boxProps}>{label}</Box>
      </TableSortLabel>
    </TableCell>
  )
}
