import React, { useRef, useEffect } from 'react'
import { useField } from 'react-final-form'

import { Add, FindReplace } from '@material-ui/icons'
import { Badge, Button } from '@material-ui/core'

import { FileTypes } from 'utils/types'
import { composeFileValidators, composeValidators } from 'utils/formValidators'

type UploadFileFieldProps<DataType> = {
  name: keyof DataType | string
  text: string
  validate: ReturnType<typeof composeValidators>
  validateFile: ReturnType<typeof composeFileValidators>
  accept: FileTypes
}

export const FileField = <DataType extends object>({
  name,
  text,
  validate,
  validateFile,
  accept,
}: UploadFileFieldProps<DataType>): JSX.Element => {
  const fieldRef = useRef<HTMLInputElement>(null)
  const fieldId = `file-${name}`
  const {
    input: { type, name: fieldName, onChange, value },
    meta,
  } = useField(name as string, {
    type: 'File',
    validate: val => {
      const valueError = validate(val)

      if (valueError) return valueError

      const fileError = validateFile(fieldRef)
      if (fileError) return fileError

      return undefined
    },
  })

  // TODO: form doesnt pick validation of this field on initial load, find how to remove this workaround
  useEffect(() => {
    setTimeout(() => onChange({ target: { value: { value, file: undefined } } }), 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasFile = Boolean(value.file)

  return (
    <>
      <input
        hidden
        type={type}
        id={fieldId}
        ref={fieldRef}
        value={value?.value ?? ''}
        accept={accept}
        name={fieldName}
        onChange={({ target: { files, value: val } }) => {
          if (!files?.length) {
            return
          }

          const [file] = files

          onChange({ target: { value: { value: val, file } } })
        }}
      />
      <label htmlFor={fieldId}>
        <Badge
          badgeContent={meta.error ? meta.error : 'ok'}
          title={meta.error}
          color="secondary"
        >
          <Button variant="contained" color="primary" component="span">
            {hasFile ? (
              <>
                <FindReplace />
                {value.file.name}
              </>
            ) : (
              <>
                <Add />
                {text}
              </>
            )}
          </Button>
        </Badge>
      </label>
    </>
  )
}
