import Axios, { AxiosPromise } from 'axios'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { API_HOST } from 'utils/consts'

export const cerberusRequest = <PayloadType = undefined, ReturnType = undefined>(
  params: Partial<Omit<AxiosRequestConfig, 'baseURL' | 'data' | 'cancelToken'>> & {
    data?: PayloadType
  },
) => {
  const requestToken = Axios.CancelToken.source()

  const pendingRequest = axios.request<PayloadType, AxiosResponse<ReturnType>>({
    baseURL: API_HOST,
    method: 'POST',
    ...params,
    cancelToken: requestToken.token,
  }) as AxiosPromise<ReturnType> & { cancel: Function }

  pendingRequest.cancel = () => requestToken.cancel()

  return pendingRequest
}
