import { Response } from 'miragejs'

import { AdminAPIRoutePaths } from 'utils/consts'
import { getFullApiRoutePath } from 'utils/url'
import { PartnerEmail, PartnerItem } from 'utils/types'

import faker from 'faker'
import { MockServerEndpointHandler } from './types'
import { createMockPartnerEmail } from '../modelFactories'
import { UpdatePartnerVariables } from '../../../pages/admin/ManagePartnersTable'

export const managePartnersHandlers: MockServerEndpointHandler = mockServer => {
  const { get, post, put } = mockServer

  get(
    getFullApiRoutePath(AdminAPIRoutePaths.PartnersFraud),
    schema => schema.all('partner').models,
  )

  post(getFullApiRoutePath(AdminAPIRoutePaths.PartnersFraud), (schema, request) => {
    const params: { email: PartnerEmail['email'] } & Pick<PartnerItem, 'domain_name'> =
      JSON.parse(request.requestBody)

    if (params.email) {
      const [emailName, domain_name] = params.email.split('@')
      const email = createMockPartnerEmail(domain_name, emailName)

      const partnerItem = schema.findBy('partner', { domain_name })

      if (partnerItem) {
        partnerItem.update({
          whitelisted_emails: [...partnerItem.whitelisted_emails, email],
        })

        return new Response(204)
      }

      return mockServer.create('partner', { domain_name, whitelisted_emails: [email] })
    }

    return mockServer.create('partner', {
      domain_name: params.domain_name,
      whitelisted_emails: [],
    })
  })

  put(
    `${getFullApiRoutePath(AdminAPIRoutePaths.PartnersFraud)}/:partnerId`,
    (schema, request) => {
      const { domain_name, whitelisted_emails, ...params }: UpdatePartnerVariables =
        JSON.parse(request.requestBody)

      schema
        .findBy('partner', {
          id: request.params.partnerId,
        })
        ?.update({
          domain_name,
          whitelisted_emails: whitelisted_emails.map(email => ({
            email,
            id: faker.random.uuid(),
          })),
          ...params,
        })

      return new Response(201)
    },
  )

  get(getFullApiRoutePath(AdminAPIRoutePaths.PartnersRequestForInformation), schema =>
    schema.all('partner').models.reverse(),
  )
  post(
    getFullApiRoutePath(AdminAPIRoutePaths.PartnersRequestForInformation),
    (schema, request) => {
      const params: { email: PartnerEmail['email'] } & Pick<PartnerItem, 'domain_name'> =
        JSON.parse(request.requestBody)

      if (params.email) {
        const [emailName, domain_name] = params.email.split('@')
        const email = createMockPartnerEmail(domain_name, emailName)

        // @ts-expect-error schema.partners type missing
        const partner = schema.partners.findBy<PartnerItem>({
          domain_name,
        })

        if (partner) {
          return partner.update({
            whitelisted_emails: [...partner.whitelisted_emails, email],
          })
        }

        return mockServer.create('partner', {
          domain_name,
          whitelisted_emails: [email],
        })
      }

      return mockServer.create('partner', {
        domain_name: params.domain_name,
        whitelisted_emails: [],
      })
    },
  )

  get(getFullApiRoutePath(AdminAPIRoutePaths.RequestForInformationPendingUsers), schema =>
    schema.all('pendingPartner').models.reverse(),
  )

  post(
    getFullApiRoutePath(AdminAPIRoutePaths.RequestForInformationPendingUserApprove),
    () => new Response(200),
  )

  post(
    getFullApiRoutePath(AdminAPIRoutePaths.RequestForInformationPendingUserReject),
    () => new Response(200),
  )
}
