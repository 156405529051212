import React, { FunctionComponent, ReactElement } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { Grid } from '@material-ui/core'

import { AddTransactionButton } from 'components/buttons'
import { initialTransactions } from 'utils/consts'
import { Transaction } from 'utils/types'
import { TransactionCard } from './TransactionCard'
import { TransactionContext } from './TransactionContext'

type TransactionsProps = {
  children?: ReadonlyArray<ReactElement> | ReactElement
  disableAddButton?: boolean
}

export const Transactions: FunctionComponent<TransactionsProps> = ({
  children: additionalFields,
  disableAddButton = false,
}) => {
  const { fields } = useFieldArray<Transaction>('transactions', {
    initialValue: initialTransactions,
  })

  return (
    <div key={fields.length}>
      {fields.map((field, index) => (
        <div key={field}>
          <TransactionContext.Provider value={{ fields, field, index, additionalFields }}>
            <TransactionCard />
          </TransactionContext.Provider>
        </div>
      ))}
      {!disableAddButton && (
        <Grid item xs={12} sm={6} md={4}>
          <AddTransactionButton fields={fields} initialValue={initialTransactions[0]} />
        </Grid>
      )}
    </div>
  )
}
