/* istanbul ignore file */ // POS will be removed after approval
import React from 'react'
import { flow } from 'lodash/fp'
import { subDays } from 'date-fns/fp'
import { useQuery } from 'react-query'

import { Box, FormControl, FormLabel, Grid, Link, Typography } from '@material-ui/core'
import { Preloader } from 'components'
import {
  AffirmationCheckBoxItem,
  AffirmationDateRangeItem,
  AffirmationsList,
  RequestForInformationDocumentAffirmationText,
} from 'components/forms/commonFields'
import { dateToISO, getCurrentDate, getCurrentDateISO } from 'utils'
import { APIRoutePaths, RoutePathsDynamicKeys } from 'utils/consts'
import { useAtLeastOneSelectedValidation } from 'utils/formValidators'
import { getFullApiRoutePath, setApiRoutePathDynamicKey } from 'utils/url'

import { RequestForInformationFormFieldsKeys } from '../types'
import { TEXT_REQUEST_FOR_INFORMATION } from '../RequestForInformation'
import { useRequestDocumentsMutation } from './useRequestDocumentsMutation'
import { getRequestForInformationSampleDocs } from '../stepsValidationQueries'
import { RequestForInformationFormStepperButton } from '../RequestForInformationFormStepperButton'

export const SelectDocumentsStep = (): JSX.Element => {
  const requestDocuments = useRequestDocumentsMutation()

  useAtLeastOneSelectedValidation(
    RequestForInformationFormFieldsKeys.SelectDocumentsAffirmations,
  )

  const get31ISODayBeforeCurrent = flow(getCurrentDate, subDays(31), dateToISO)

  const { data, isLoading, isFetching } = useQuery(
    APIRoutePaths.RequestForInformationSampleDocs,
    getRequestForInformationSampleDocs,
    { retry: false },
  )

  if (isLoading) return <Preloader />
  if (!data?.data) return <div>No documents</div>

  const affirmations = data.data.map(item => {
    const { name, is_timeframed, type } = item

    let affirmationItem: RequestForInformationDocumentAffirmationText = {
      type,
      id: name,
      text: name,
      value: false,
      render: ({ name: fieldName }) => (
        <Grid container alignItems="center">
          <Grid item>
            <AffirmationCheckBoxItem
              name={fieldName}
              text={name}
              afterTitle={
                <sup>
                  <Typography
                    variant="caption"
                    component={Link}
                    href={getFullApiRoutePath(
                      setApiRoutePathDynamicKey(
                        APIRoutePaths.RequestForInformationSampleDoc,
                        RoutePathsDynamicKeys.RequestForInformationDocumentType,
                        type,
                      ),
                    )}
                    target="_blank"
                  >
                    sample
                  </Typography>
                </sup>
              }
            >
              {is_timeframed
                ? () => {
                    return <AffirmationDateRangeItem name={fieldName} />
                  }
                : undefined}
            </AffirmationCheckBoxItem>
          </Grid>
        </Grid>
      ),
    }

    if (is_timeframed) {
      affirmationItem = {
        ...affirmationItem,
        start_date: get31ISODayBeforeCurrent(),
        end_date: getCurrentDateISO(),
      }
    }

    return affirmationItem
  })

  return (
    <>
      <FormControl margin="normal" component="fieldset">
        <Box mb={4}>
          <FormLabel component="legend">
            <strong>{TEXT_REQUEST_FOR_INFORMATION.affirmationsHeading2}</strong>
          </FormLabel>
        </Box>
        <AffirmationsList
          name={RequestForInformationFormFieldsKeys.SelectDocumentsAffirmations}
          initialValues={affirmations}
        />
      </FormControl>

      <RequestForInformationFormStepperButton
        progress={isFetching}
        onClick={requestDocuments}
      />
    </>
  )
}
