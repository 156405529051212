import { useEffect, useState } from 'react'
import { navigate, useLocation } from '@reach/router'

import { useNotifications, useSearchParams } from 'utils'
import { ClientRoutePaths, CommonErrorMessages } from 'utils/consts'
import { useCerberusAuth } from 'services/CerberusAuth'
import { getUserAccess } from 'services/security'

export const usePrivateRoute = (accessPermissions: ReadonlyArray<string>) => {
  const searchParams = useSearchParams()
  const { pathname } = useLocation()

  const { toastError } = useNotifications()
  const { isLoading, validateToken } = useCerberusAuth()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    validateToken().then(response => {
      const permissions = response?.data?.data?.permissions ?? []
      const userAccess = getUserAccess(permissions)
      const hasAccess = accessPermissions.some(accessKey => userAccess[accessKey])

      if (!hasAccess) {
        toastError(CommonErrorMessages.HasNoAccess)
        navigate(ClientRoutePaths.Login)
        return
      }

      setVisible(true)

      if (Object.keys(searchParams).length) navigate(pathname)
    })
    // todo: refactor page privacy mechanism -> page should not read auth response directly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoading, isVisible }
}
