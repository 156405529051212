import React, { createContext, useContext, FunctionComponent } from 'react'
import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { AdminAPIRoutePaths } from 'utils/consts'
import { cerberusRequest } from 'services/Cerberus'
import { redirectToSignIn } from 'services/IDaveAuth'
import { PartnerEmail, PartnerItem, SettingsPartnersEndpointResponse } from 'utils/types'
import {
  AddPartnerHandler,
  AddPartnerHandlerResponse,
  DeletePartnerByIdHandler,
  ManagePartnersContextType,
  UpdatePartnerByIdHandler,
} from './types'
import { getPartnersQuery } from './queries'

type AddPartnerMutationVariables =
  | Pick<PartnerEmail, 'email'>
  | Pick<PartnerItem, 'domain_name'>

const managePartnersContext = createContext<ManagePartnersContextType>(
  {} as ManagePartnersContextType,
)

export const ManagePartnersContext: FunctionComponent = ({ children }) => {
  const { data, isLoading, refetch } = useQuery<
    AxiosResponse<SettingsPartnersEndpointResponse>,
    AxiosError
  >(AdminAPIRoutePaths.PartnersFraud, {
    queryFn: () => getPartnersQuery(AdminAPIRoutePaths.PartnersFraud),
    onError: ({ code }) => {
      if (code === '401' || code === '403') {
        redirectToSignIn()
      }
    },
  })

  const addPartner: AddPartnerHandler = async payload => {
    const response = await cerberusRequest<
      AddPartnerMutationVariables,
      AddPartnerHandlerResponse
    >({
      url: AdminAPIRoutePaths.PartnersFraud,
      data: payload,
    })

    await refetch()

    return response
  }

  const deletePartnerById: DeletePartnerByIdHandler = async id => {
    await cerberusRequest({
      url: `${AdminAPIRoutePaths.PartnersFraud}/${id}`,
      method: 'DELETE',
    })

    await refetch()
  }

  const updatePartnerById: UpdatePartnerByIdHandler = async (id, payload) => {
    await cerberusRequest({
      url: `${AdminAPIRoutePaths.PartnersFraud}/${id}`,
      data: payload,
      method: 'PUT',
    })

    await refetch()
  }

  const contextValue: ManagePartnersContextType = {
    loading: isLoading,
    partners: data?.data ?? [],
    addPartner,
    deletePartnerById,
    updatePartnerById,
  }

  return (
    <managePartnersContext.Provider value={contextValue}>
      {children}
    </managePartnersContext.Provider>
  )
}

export const useManagePartners = () => useContext(managePartnersContext)
