import React, { FunctionComponent } from 'react'
import { useNavigate } from '@reach/router'
import { Button } from '@material-ui/core'

import { ClientRoutePaths } from 'utils/consts'

type NavigateButtonProps = {
  path: ClientRoutePaths
  text: string
}
export const NavigateButton: FunctionComponent<NavigateButtonProps> = ({
  path,
  text,
}) => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => navigate(path)}
      title={text}
      variant="contained"
      color="primary"
      fullWidth
    >
      {text}
    </Button>
  )
}
