import faker from 'faker'
import { Response } from 'miragejs'

import { APIRoutePaths } from 'utils/consts'
import { getFullApiRoutePath } from 'utils/url'
import { RequestForInformationSampleDocument } from 'utils/types'

import {
  GetRequestForInformationSampleDocsResponse,
  IdentificationStepValidationQueryResponse,
} from 'pages/RequestForInformation/stepsValidationQueries'
import { RequestForInformationReviewQueryResponse } from 'pages/RequestForInformation/RequestForInformationReview'
import { MockServerEndpointHandler } from './types'

export const requestForInformationHandlers: MockServerEndpointHandler = ({
  post,
  get,
}): void => {
  get(
    getFullApiRoutePath(APIRoutePaths.RequestForInformationSampleDocs),
    (schema): GetRequestForInformationSampleDocsResponse =>
      schema
        .all('rfiDocument')
        .models.map(
          ({ name, type, is_timeframed }): RequestForInformationSampleDocument => {
            return { name, type, is_timeframed }
          },
        ),
  )

  // validate
  post(
    getFullApiRoutePath(
      APIRoutePaths.RequestForInformationAccountIdentificationValidation,
    ),
    () => {
      const res: IdentificationStepValidationQueryResponse = {
        rfi_id: faker.random.uuid(),
        status: 'USER_VALID',
      }

      return new Response(200, {}, res)
    },
  )

  // upload document
  post(
    getFullApiRoutePath(APIRoutePaths.RequestForInformationUploadReferenceDocument),
    () => new Response(204),
  )

  // download documents list
  get(
    getFullApiRoutePath(APIRoutePaths.RequestForInformationDownloadDocumentsList),
    schema =>
      schema.all('rfiDocument').models.map(({ name, type, id }) => ({ name, type, id })),
  )

  // request documents
  post(getFullApiRoutePath(APIRoutePaths.RequestForInformationRequestDocs), () => ({
    reference: faker.random.number({ min: 1, max: 99999 }),
  }))

  post(
    getFullApiRoutePath(APIRoutePaths.RequestForInformationApproveRequest),
    () => new Response(200),
  )

  post(
    getFullApiRoutePath(APIRoutePaths.RequestForInformationRejectRequest),
    () => new Response(200),
  )

  get(getFullApiRoutePath(APIRoutePaths.RequestForInformationReview), schema => {
    const response: RequestForInformationReviewQueryResponse = {
      requester: faker.internet.email(),
      rfi_id: faker.random.uuid(),
      reference: faker.random.number({ min: 1000, max: 2000 }).toString(),
      documents: schema
        .all('rfiDocument')
        .models.map(({ name, type }) => ({ name, type, id: faker.random.uuid() })),
    }

    return new Response(200, {}, response)
  })
}
