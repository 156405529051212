import React, { useState } from 'react'
import {
  Paper,
  TableRow,
  TableContainer,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  Box,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { formatISO, parseISO } from 'date-fns'

import { Preloader } from 'components'
import { stableSort, getComparator, Order } from './tableHandlers'
import { TableHeaders } from './TableHeaders'
import { UserReport } from './types'
import { useGetUserReports } from './useGetUserReports'
import { EmptyTableWidget } from './EmptyTableWidget'
import { ReportStatusIcon } from './ReportStatusIcon'

type ReportsTableProps = {
  title: string
}

const TableRowStyled = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

export const ROWS_PER_PAGE = 10

export const ReportsTable = ({ title }: ReportsTableProps): JSX.Element | null => {
  const [order, setOrder] = React.useState<Order>()
  const [orderBy, setOrderBy] = React.useState<keyof UserReport>()
  const [pageNumber, setPage] = useState(0)

  const { data, isLoading, isFetching } = useGetUserReports({
    skip: pageNumber * ROWS_PER_PAGE,
    limit: ROWS_PER_PAGE,
  })

  if (isLoading) return <Preloader />
  if (!data) return null

  const handleRequestSort = (_event: unknown, property: keyof UserReport) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const {
    data: { reports, page_info },
  } = data

  const handleChangePage = (_event: unknown, newPage: number) => setPage(newPage)

  return (
    <div>
      <Paper>
        <Box p={2}>
          <Typography variant="h6">
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
        </Box>
        {reports.length ? (
          <Box mb={2}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableHeaders
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={reports.length}
                />
                <TableBody>
                  {stableSort(reports, getComparator(order, orderBy)).map(row => (
                    <TableRowStyled tabIndex={-1} key={row.id}>
                      <TableCell>
                        <Box pl={2}>{row.reference_id}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{row.description}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {formatISO(parseISO(row.created_at), {
                            representation: 'date',
                          })}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>{row.type}</Box>
                      </TableCell>
                      <TableCell>
                        <div title={row.status}>
                          <ReportStatusIcon status={row.status} />
                        </div>
                      </TableCell>
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {page_info.total_items > ROWS_PER_PAGE &&
              (isFetching ? (
                <Preloader wrapper={{ height: '2.9rem' }} preloader={{ size: '1rem' }} />
              ) : (
                <TablePagination
                  display="flex"
                  justifyContent="center"
                  component={Box}
                  count={page_info.total_items}
                  rowsPerPage={page_info.per_page}
                  rowsPerPageOptions={[page_info.per_page]}
                  page={pageNumber}
                  onChangePage={handleChangePage}
                />
              ))}
          </Box>
        ) : (
          <EmptyTableWidget />
        )}
      </Paper>
    </div>
  )
}
