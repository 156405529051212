import React from 'react'
import { FormControl, Grid } from '@material-ui/core'
import { requiredField } from 'utils/formValidators'
import { AuthorisedPushPaymentFormFieldTransactionKeys } from 'utils'
import { useTransactionContext } from './Transactions/TransactionContext'
import { TextInput } from './TextInput'

export const TEXT_BeneficiaryField = 'Sender (Victim) name'

export const BeneficiaryField = () => {
  const { field } = useTransactionContext()

  return (
    <Grid item xs={12} sm={8} md={6}>
      <FormControl fullWidth margin="normal">
        <TextInput
          placeholder={TEXT_BeneficiaryField}
          name={`${field}.${AuthorisedPushPaymentFormFieldTransactionKeys.Beneficiary}`}
          validate={requiredField}
        />
      </FormControl>
    </Grid>
  )
}
