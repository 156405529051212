import React, { FunctionComponent } from 'react'

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { PersonAddRounded } from '@material-ui/icons'

import { useAddPartnerHandler } from './useAddPartnerHandler'

export const TEXT_AddPartner = {
  placeholder: 'add partner email or domain',
}

export const AddPartner: FunctionComponent = () => {
  const { keyPressHandler, submitHandler, loading, value, inputActions } =
    useAddPartnerHandler()

  return (
    <Grid item md={6}>
      <FormControl fullWidth>
        <TextField
          disabled={loading}
          variant="outlined"
          value={value}
          onChange={inputActions.onChange}
          placeholder={TEXT_AddPartner.placeholder}
          onKeyPress={keyPressHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    disabled={!value.length}
                    color={value.length ? 'primary' : 'default'}
                    onClick={submitHandler}
                  >
                    <PersonAddRounded />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  )
}
