import { useLocation, useNavigate } from '@reach/router'
import qs from 'query-string'
import { AdminClientRoutePaths, ClientRoutePaths } from './consts'

export const useSearchParams = <
  DataType = Record<string, string | number>,
>(): DataType => {
  const { search } = useLocation()

  return qs.parse(search) as unknown as DataType
}

export const useNavigateWithSearchParams = () => {
  const navigate = useNavigate()
  const searchObj = useSearchParams()

  const searchStr = qs.stringify(searchObj)

  return (path: ClientRoutePaths | AdminClientRoutePaths) =>
    navigate(`${path}?${searchStr}`)
}
