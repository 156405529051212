import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'

export const CardNotPresentHeader: FunctionComponent = () => (
  <>
    <Box>
      <Typography paragraph>
        Please use this form to report &nbsp;
        <strong>card‑not‑present fraud</strong> to Revolut.
      </Typography>
      <Typography paragraph>
        It will help us to identify the fraudster much faster and increase the chances of
        refunding your losses.
      </Typography>
    </Box>
  </>
)
