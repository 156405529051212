import React, { FunctionComponent } from 'react'
import { Tooltip } from '@material-ui/core'
import { DomainDisabledOutlined, DomainOutlined } from '@material-ui/icons'
import { PartnerItem } from 'utils/types'

type DomainWhiteListedIconProps = Pick<PartnerItem, 'is_whole_domain_whitelisted'>

const TEXT_DomainWhiteListedIcon = {
  true: 'domain fully white listed',
  false: 'domain uses emails list',
}

export const DomainWhiteListedIcon: FunctionComponent<DomainWhiteListedIconProps> = ({
  is_whole_domain_whitelisted,
}) => (
  <Tooltip title={TEXT_DomainWhiteListedIcon[String(is_whole_domain_whitelisted)]}>
    {is_whole_domain_whitelisted ? <DomainOutlined /> : <DomainDisabledOutlined />}
  </Tooltip>
)
