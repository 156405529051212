import React from 'react'
import { useField, UseFieldConfig } from 'react-final-form'
import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

type AffirmationRadioItemProps<DataType> = Pick<UseFieldConfig<DataType>, 'validate'> & {
  text: string
  name: string
}

export const AffirmationRadioItem = <DataType extends { value: boolean }>({
  name,
  text,
  validate,
}: AffirmationRadioItemProps<DataType>): JSX.Element => {
  const {
    input: { name: fieldName, value: fieldObj, onChange },
  } = useField(name, { type: 'Radio', validate })

  return (
    <>
      <Box my={1}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormLabel component="legend">{text}</FormLabel>
          </Grid>
        </Grid>
      </Box>
      <RadioGroup
        name={fieldName}
        value={fieldObj.value ? String(fieldObj.value) : 'false'}
        onChange={({ target: { value } }) =>
          onChange({ target: { value: { ...fieldObj, value: JSON.parse(value) } } })
        }
      >
        <Grid container>
          <Grid item>
            <FormControlLabel value="true" control={<Radio />} label="yes" />
          </Grid>
          <Grid item>
            <FormControlLabel value="false" control={<Radio />} label="no" />
          </Grid>
        </Grid>
      </RadioGroup>
    </>
  )
}
