import { jsonToFormData } from 'utils'
import { setApiRoutePathDynamicKey } from 'utils/url'
import { APIRoutePaths, RoutePathsDynamicKeys } from 'utils/consts'
import { AccountType, RequestForInformationSampleDocument } from 'utils/types'

import { cerberusRequest } from 'services/Cerberus'

export type IdentificationStepValidationQueryVariables = {
  account_type: AccountType
  iban?: string
  account?: {
    sort_code: number
    account_number: number
  }
}

export type IdentificationStepValidationQueryResponse = {
  rfi_id: string
  status: 'INTERNAL_ERROR' | 'USER_NOT_VALID' | 'USER_NOT_FOUND' | 'USER_VALID'
}

export const validateIdentificationStep = (
  data: IdentificationStepValidationQueryVariables,
) =>
  cerberusRequest<
    Omit<IdentificationStepValidationQueryVariables, 'token'>,
    IdentificationStepValidationQueryResponse
  >({
    data,
    url: APIRoutePaths.RequestForInformationAccountIdentificationValidation,
  })

export type UploadDocumentStepValidationQueryVariables = {
  is_fraudulent_activity: boolean
  is_money_laundering: boolean
  document: File
  rfi_id: string
}

export const validateUploadDocumentStep = (
  data: UploadDocumentStepValidationQueryVariables,
) =>
  cerberusRequest<FormData, void>({
    data: jsonToFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: setApiRoutePathDynamicKey(
      APIRoutePaths.RequestForInformationUploadReferenceDocument,
      RoutePathsDynamicKeys.RequestForInformationId,
      data.rfi_id,
    ),
  })

export type GetRequestForInformationSampleDocsResponse =
  Array<RequestForInformationSampleDocument>

export const getRequestForInformationSampleDocs = () =>
  cerberusRequest<never, GetRequestForInformationSampleDocsResponse>({
    url: APIRoutePaths.RequestForInformationSampleDocs,
    method: 'GET',
  })
