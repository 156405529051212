/* istanbul ignore file */ // POS will be removed after approval
import React from 'react'
import { Box, FormLabel } from '@material-ui/core'

import { FileTypes } from 'utils/types'
import { AffirmationsList, FileField } from 'components/forms/commonFields'
import {
  composeFileValidators,
  maxFileSize,
  mustBePdf,
  requiredField,
} from 'utils/formValidators'

import { RequestForInformationFormStepperButton } from '../RequestForInformationFormStepperButton'
import { useUploadDocumentStepHandler } from './useUploadDocumentStepHandler'
import { RequestForInformationFormFieldsKeys } from '../types'

const MAX_UPLOAD_SIZE_KB = 10240

export const TEXT_UPLOAD_DOCUMENT_STEP = {
  heading: 'Upload official request document',
  uploadFIleButtonText: 'upload document',
}

export const UploadDocumentStep = (): JSX.Element => {
  const { refetch: validateStep, isFetching } = useUploadDocumentStepHandler()

  return (
    <Box my={4}>
      <Box>
        <FormLabel component="legend">
          <strong>{TEXT_UPLOAD_DOCUMENT_STEP.heading}</strong>
        </FormLabel>
      </Box>

      <Box mt={1} my={6}>
        <FileField
          name="document"
          text={TEXT_UPLOAD_DOCUMENT_STEP.uploadFIleButtonText}
          accept={FileTypes.Pdf}
          validate={requiredField}
          validateFile={composeFileValidators(mustBePdf, maxFileSize(MAX_UPLOAD_SIZE_KB))}
        />
      </Box>

      <Box mb={4}>
        <AffirmationsList
          name={RequestForInformationFormFieldsKeys.UploadDocumentAffirmations}
        />
      </Box>

      <RequestForInformationFormStepperButton
        progress={isFetching}
        onClick={validateStep}
      />
    </Box>
  )
}
