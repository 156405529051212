import React from 'react'

import { Link } from '@reach/router'
import { Box, Button } from '@material-ui/core'

import { AccountType } from 'utils/types'
import { ClientRoutePaths } from 'utils/consts'
import { HeaderContent } from 'components'
import { AccountTypeField } from 'components/forms/commonFields'

import { TEXT_IDENTIFICATION_STEP } from './texts'
import { AccountNumberField } from './AccountNumberField'
import { TEXT_REQUEST_FOR_INFORMATION } from '../RequestForInformation'
import { useIdentificationStepValidationQuery } from './useIdentificationStepValidationQuery'
import { RequestForInformationFormStepperButton } from '../RequestForInformationFormStepperButton'

export const IdentificationStep = (): JSX.Element | null => {
  const {
    refetch: validateStep,
    isFetching,
    data,
  } = useIdentificationStepValidationQuery()

  if (data && data.data.status !== 'USER_VALID') {
    const errorText = TEXT_IDENTIFICATION_STEP.errorText[data.data.status]

    return (
      <Box mt={4}>
        <HeaderContent
          heading={errorText}
          paragraph={TEXT_IDENTIFICATION_STEP.errorText.paragraph}
        />
        <Box my={4}>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to={ClientRoutePaths.Login}
          >
            {TEXT_IDENTIFICATION_STEP.backToPortalButtonText}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <AccountTypeField
        label={TEXT_REQUEST_FOR_INFORMATION.accountTypeFieldLabel}
        exclude={[AccountType.WalletRef, AccountType.CardNumber]}
      />
      <Box mb={4}>
        <AccountNumberField />
      </Box>

      <RequestForInformationFormStepperButton
        progress={isFetching}
        onClick={validateStep}
      />
    </>
  )
}
