import React, { FunctionComponent } from 'react'
import { SaveOutlined } from '@material-ui/icons'

import { TooltipButton, TooltipButtonProps } from 'components/buttons'

export const SaveButton: FunctionComponent<TooltipButtonProps> = props => (
  <TooltipButton {...props}>
    <SaveOutlined />
  </TooltipButton>
)
