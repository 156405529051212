import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { FormControl, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import {
  DeleteOutlineRounded,
  UnfoldMoreOutlined,
  UnfoldLessOutlined,
  CancelPresentationOutlined,
} from '@material-ui/icons'

import { TooltipButton } from 'components/buttons'
import { Nullable, PartnerEmail } from 'utils/types'
import { StyledTreeItem } from 'pages/admin/components/StyledTreeItem'
import { composeValidators, mustBeEmail, requiredField } from 'utils/formValidators'
import { TextInput } from 'components/forms/commonFields'
import { useEditContext } from './PartnerCardEditContext'

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

const EMAIL_LIST_DEFAULT_FOLD_LIMIT = 10

export const TEXT_EmailsList = {
  label: 'Emails',
}

export const EmailsList = (): Nullable<JSX.Element> => {
  const { isEdit } = useEditContext()
  const classes = useStyles()

  const { fields } = useFieldArray<PartnerEmail>('whitelisted_emails')

  const isInitiallyExpanded =
    (fields?.length ?? 0) < EMAIL_LIST_DEFAULT_FOLD_LIMIT ? ['0'] : undefined

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<UnfoldLessOutlined />}
      defaultExpandIcon={<UnfoldMoreOutlined />}
      defaultEndIcon={<CancelPresentationOutlined />}
      defaultExpanded={isInitiallyExpanded}
    >
      <StyledTreeItem nodeId="0" label={TEXT_EmailsList.label}>
        {fields.map((fieldName, index) => {
          const item = fields.value[index]

          return isEdit ? (
            <Grid container spacing={3} key={item.id} alignItems="center">
              <Grid item md={5}>
                <FormControl fullWidth>
                  <TextInput
                    name={`${fieldName}.email`}
                    validate={composeValidators(requiredField, mustBeEmail)}
                    initialValue={item.email}
                    variant="standard"
                    disabled
                  />
                </FormControl>
                <Hidden xsUp>
                  <TextInput
                    name={`${fieldName}.id`}
                    validate={composeValidators(requiredField)}
                    initialValue={item.id}
                  />
                </Hidden>
              </Grid>
              <TooltipButton
                title={`delete ${item.email}`}
                onClick={() => fields.remove(index)}
              >
                <DeleteOutlineRounded />
              </TooltipButton>
            </Grid>
          ) : (
            <Typography key={item.id}>{item.email}</Typography>
          )
        })}
      </StyledTreeItem>
    </TreeView>
  )
}
