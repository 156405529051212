import React, { FunctionComponent } from 'react'
import { HeaderContent } from 'components'

export const TEXT_AuthorisedPushPaymentFormConfirmation = {
  heading: `Thank you for using this form to report Authorised Push Payment (APP/Scam) fraud to Revolut, we’ll process it according to our internal processes as soon as possible. Please send us an indemnity letter with a number <b>{zendesk_id}</b> in the email subject to <b>fraudops@revolut.com</b> in order for us to refund any remaining funds after our internal investigation.`,
  paragraph: 'You’ll also receive an acknowledgement email shortly.',
}

type AuthorisedPushPaymentFormConfirmationProps = {
  referenceNumber: string
}

export const AuthorisedPushPaymentFormConfirmation: FunctionComponent<AuthorisedPushPaymentFormConfirmationProps> =
  ({ referenceNumber }) => (
    <HeaderContent
      heading={TEXT_AuthorisedPushPaymentFormConfirmation.heading.replace(
        '{zendesk_id}',
        referenceNumber,
      )}
      paragraph={TEXT_AuthorisedPushPaymentFormConfirmation.paragraph}
    />
  )
