import { useMemo } from 'react'
import { navigate } from '@reach/router'

import { ClientRoutePaths } from 'utils/consts'
import { useCerberusAuth } from 'services/CerberusAuth'

export const TEXT_USER_ACTIONS = {
  myReports: 'My reports',
  LogOut: 'Log out',
}

export const useUserActions = (): ReadonlyArray<{
  title: string
  visible: boolean
  actionHandler: () => void
}> => {
  const { tokenData, access, logOut } = useCerberusAuth()

  return useMemo(
    () => [
      {
        title: TEXT_USER_ACTIONS.myReports,
        visible: access && Object.values(access).some(item => item),
        actionHandler: () => navigate(ClientRoutePaths.Reports),
      },
      {
        title: TEXT_USER_ACTIONS.LogOut,
        visible: Boolean(tokenData),
        actionHandler: () => {
          navigate(ClientRoutePaths.Root)
          logOut(undefined)
        },
      },
    ],
    [access, logOut, tokenData],
  )
}
