import React, { FunctionComponent } from 'react'
import { HeaderContent } from 'components'

export const TEXT_CardNotPresentFormConfirmation = {
  heading:
    'Thank you for submitting your CNP Fraud Report, we’ll process it according to our internal processes as soon as possible. We can confirm that we have received your fraud report with reference ID <b>{zendesk_id}</b.',
  paragraph: 'You’ll also receive an acknowledgement email shortly.',
}

type CardNotPresentFormConfirmationProps = {
  referenceNumber: string
}

export const CardNotPresentFormConfirmation: FunctionComponent<CardNotPresentFormConfirmationProps> =
  ({ referenceNumber }) => (
    <HeaderContent
      heading={TEXT_CardNotPresentFormConfirmation.heading.replace(
        '{zendesk_id}',
        referenceNumber,
      )}
      paragraph={TEXT_CardNotPresentFormConfirmation.paragraph}
    />
  )
