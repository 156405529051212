import React from 'react'
import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@material-ui/core'

type PreloaderProps = {
  wrapper?: BoxProps
  preloader?: CircularProgressProps
}

export const TEXT_PRELOADER = 'Loading'

export const Preloader = ({ wrapper, preloader }: PreloaderProps): JSX.Element => (
  <Box
    title={TEXT_PRELOADER}
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="70vh"
    {...wrapper}
  >
    <CircularProgress {...preloader} />
  </Box>
)
