import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useUserActions } from './useUserActions'

const useStyles = makeStyles(theme => ({
  link: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}))

export const UserActionBox = (): JSX.Element => {
  const classNames = useStyles()
  const actionsList = useUserActions()

  return (
    <Grid container spacing={2}>
      {actionsList.map(
        ({ visible = true, title, actionHandler }) =>
          visible && (
            <Grid item key={title}>
              <Link
                component="button"
                color="textPrimary"
                onClick={actionHandler}
                className={classNames.link}
              >
                <Typography>{title}</Typography>
              </Link>
            </Grid>
          ),
      )}
    </Grid>
  )
}
