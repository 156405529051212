import { AxiosError } from 'axios'
import { defaultTo, flow, get } from 'lodash/fp'
import { QueryErrorType } from './types'

export const isAxiosError = flow(get('isAxiosError'), defaultTo(false))

export const getErrorStatusCode = (error: AxiosError) => error.response?.status

export const isStatusUnauthorised = (error: QueryErrorType) =>
  getErrorStatusCode(error as AxiosError) === 401

export const isStatusConflict = (error: QueryErrorType) =>
  getErrorStatusCode(error as AxiosError) === 409

export const isStatusForbidden = (error: QueryErrorType) =>
  getErrorStatusCode(error as AxiosError) === 403

export const isStatusNotFound = (error: QueryErrorType) =>
  getErrorStatusCode(error as AxiosError) === 404

export const isStatusCustomError = (error: QueryErrorType) =>
  getErrorStatusCode(error as AxiosError) === 422
