import { AffirmationsListType } from 'components/forms/commonFields'

export enum RequestForInformationApprovalDecision {
  Approve = 'approve',
  Reject = 'reject',
}

export type RequestForInformationReviewFormState = {
  rejectionReason?: string
  affirmations: AffirmationsListType
  decision: RequestForInformationApprovalDecision
}
