import React, { FunctionComponent, createContext } from 'react'
import { useAuthService } from './useAuthService'

export type AuthContext = ReturnType<typeof useAuthService>
export const defaultAuthContext = { isLoading: false }

export const AuthContext = createContext<AuthContext>(defaultAuthContext as AuthContext)

export const CerberusAuthProvider: FunctionComponent = ({ children }) => (
  <AuthContext.Provider value={useAuthService()}>{children}</AuthContext.Provider>
)
