/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'
import { Box, Grid, TextField, Typography } from '@material-ui/core'

import { useFieldArray } from 'react-final-form-arrays'

import { TextInput } from 'components/forms/commonFields'
import { AddButton } from './addButton'

export const TEXT_AUTOMATION_VARIABLES = {
  heading: 'Variables',
  addVariable: 'Add variable',
  testVariable: 'Test',
  labels: {
    variableName: 'Variable name',
    regEx: 'RegularExpression',
    output: 'Output',
  },
}

const emptyVariable = { variableName: '', regExValue: '' }
const initialValue = [emptyVariable]

export const AutomationVariables = (): JSX.Element => {
  const { fields } = useFieldArray('variables', {
    initialValue,
  })

  return (
    <>
      <Typography variant="h6" component="h3">
        {TEXT_AUTOMATION_VARIABLES.heading}
      </Typography>
      <AddButton
        onClick={() => fields.push(emptyVariable)}
        text={TEXT_AUTOMATION_VARIABLES.addVariable}
      />
      {fields.map(fieldName => (
        <Grid key={fieldName} container spacing={6} alignItems="center">
          <Grid item>
            <TextInput
              name={`${fieldName}.variableName`}
              label={TEXT_AUTOMATION_VARIABLES.labels.variableName}
            />
          </Grid>
          <Grid item>
            <TextInput
              name={`${fieldName}.regExValue`}
              label={TEXT_AUTOMATION_VARIABLES.labels.regEx}
            />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <TextField
                disabled
                variant="outlined"
                label={TEXT_AUTOMATION_VARIABLES.labels.output}
              />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
