import {
  API_HOST,
  APIRoutePaths,
  ClientRoutePaths,
  AdminAPIRoutePaths,
  RoutePathsDynamicKeys,
} from './consts'

export const getFullApiRoutePath = (path: APIRoutePaths | AdminAPIRoutePaths | string) =>
  `${API_HOST}${path}`

export const setClientRoutePathDynamicKey = (
  path: ClientRoutePaths,
  key: RoutePathsDynamicKeys,
  value: string,
) => {
  if (!path.includes(key))
    throw new Error('ClientRoutePath contain undeclared in RoutePathsDynamicKeys key')

  return path.replace(key, value)
}

export const setApiRoutePathDynamicKey = (
  path: APIRoutePaths,
  key: RoutePathsDynamicKeys,
  value: string,
) => {
  if (!path.includes(key))
    throw new Error('APIRoutePath contain undeclared in RoutePathsDynamicKeys key')

  return path.replace(key, value)
}
