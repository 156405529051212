import React, { FunctionComponent } from 'react'
import { useQuery } from 'react-query'
import { cond } from 'lodash'

import { navigate, useParams } from '@reach/router'
import { Link, Typography, Box } from '@material-ui/core'

import { Preloader } from 'components'
import { cerberusRequest } from 'services/Cerberus'
import { redirectToSignIn } from 'services/IDaveAuth'
import { RequestForInformationDocument } from 'utils/types'
import { getFullApiRoutePath, setApiRoutePathDynamicKey } from 'utils/url'
import {
  APIRoutePaths,
  ClientRoutePaths,
  CommonErrorMessages,
  RoutePathsDynamicKeys,
} from 'utils/consts'
import {
  isStatusForbidden,
  isStatusNotFound,
  isStatusUnauthorised,
} from 'utils/axiosErrors'
import { useNotifications } from 'utils/useNotifications'

type RequestForInformationDownloadsListQueryResponse =
  ReadonlyArray<RequestForInformationDocument>

export const RequestForInformationDownloads: FunctionComponent = () => {
  const { rfiId } = useParams()
  const { toastError } = useNotifications()

  const documentsUrl = setApiRoutePathDynamicKey(
    APIRoutePaths.RequestForInformationDownloadDocumentsList,
    RoutePathsDynamicKeys.RequestForInformationId,
    rfiId,
  )

  const { isLoading, data, error } = useQuery(
    APIRoutePaths.RequestForInformationDownloadDocumentsList,
    () =>
      cerberusRequest<never, RequestForInformationDownloadsListQueryResponse>({
        method: 'GET',
        url: documentsUrl,
      }),
    {
      onError: cond([
        [isStatusForbidden, () => toastError(CommonErrorMessages.HasNoAccess)],
        [
          isStatusUnauthorised,
          () => {
            toastError(CommonErrorMessages.UserNotLoggedIn)
            redirectToSignIn()
          },
        ],
        [isStatusNotFound, () => navigate(ClientRoutePaths.NotFound)],
      ]),
    },
  )

  if (isLoading) return <Preloader />

  if (error) {
    return null
  }

  if (!data?.data) return <div>No documents to download</div>

  return (
    <ul>
      {data.data.map(({ name, id }) => {
        return (
          <Typography key={name} variant="body1" component="li">
            <Box my={1}>
              <Link href={getFullApiRoutePath(`${documentsUrl}/${id}`)} target="_blank">
                {name}
              </Link>
            </Box>
          </Typography>
        )
      })}
    </ul>
  )
}
