import React, { FunctionComponent } from 'react'
import { Box, FormControl, Grid } from '@material-ui/core'

import { CardNotPresentReport } from 'utils'
import { TextInput } from 'components/forms/commonFields'

export const TEXT_DESCRIPTION_FIELD = 'RejectReason (Optional)'

export const DescriptionField: FunctionComponent = () => (
  <Box my={4}>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextInput<CardNotPresentReport>
          multiline
          rows={4}
          name="description"
          label={TEXT_DESCRIPTION_FIELD}
        />
      </FormControl>
    </Grid>
  </Box>
)
