import { AppearanceTypes, useToasts } from 'react-toast-notifications'
import { useMemo } from 'react'

export type ToastCallback = (id: string) => void

export const useNotifications = () => {
  const { addToast } = useToasts()

  return useMemo(() => {
    const showMessage =
      (appearance: AppearanceTypes) => (message: string, callback?: ToastCallback) =>
        addToast(message, { appearance, autoDismiss: true }, callback)

    return {
      toastSuccess: showMessage('success'),
      toastError: showMessage('error'),
    }
  }, [addToast])
}
