import React from 'react'
import startCase from 'lodash/startCase'
import { useField } from 'react-final-form'
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'

const useSelectFieldStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 160,
    },
  }),
)

export type SelectFieldOption = { label: string; value: number }

export type SelectFieldOptions = ReadonlyArray<SelectFieldOption>

type SelectFieldProps = {
  name: string
  label?: string
  options: SelectFieldOptions
}

export const SelectField = ({ name, label, options }: SelectFieldProps) => {
  const { input } = useField(name)
  const classNames = useSelectFieldStyles()
  const fieldLabel = label || startCase(name)

  return (
    <FormControl className={classNames.formControl} variant="outlined">
      <InputLabel id={name}>{fieldLabel}</InputLabel>
      <Select {...input} labelId={name} id={name} label={fieldLabel}>
        {options.map(({ value, label: text }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
