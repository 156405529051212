/* istanbul ignore file */
import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Box, Typography, useTheme } from '@material-ui/core'

import { Layout } from 'components'

const TEXT_NOT_FOUND = {
  heading: '404: Not Found',
  message: "Page you requested doesn't exist.",
}

export const NotFoundPage: FunctionComponent<RouteComponentProps> = (): JSX.Element => {
  const { spacing } = useTheme()

  return (
    <Layout>
      <Box minHeight="60vh" display="flex" justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h4">{TEXT_NOT_FOUND.heading}</Typography>
          <Typography style={{ paddingTop: spacing(2) }}>
            {TEXT_NOT_FOUND.message}
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}
