enum Env {
  Local = 'local',
  Dev = 'dev',
  Prod = 'prod',
}

export const getEnv = () => {
  if (window.location.hostname.endsWith('.codes')) {
    return Env.Dev
  }

  if (window.location.hostname === 'localhost') {
    return Env.Local
  }

  return Env.Prod
}

export const isDev = () => getEnv() === Env.Dev
export const isProd = () => getEnv() === Env.Prod
