/* istanbul ignore file */
import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Box, Typography, useTheme } from '@material-ui/core'

import { Layout } from 'components'

const TEXT_FORBIDDEN = {
  heading: '403: Access to requested entity is forbidden',
  message: 'Please contact.',
  email: 'ee.portal@revolut.com',
}

export const Forbidden: FunctionComponent<RouteComponentProps> = (): JSX.Element => {
  const { spacing } = useTheme()

  return (
    <Layout>
      <Box minHeight="60vh" display="flex" justifyContent="center" alignItems="center">
        <Box>
          <Typography variant="h4">{TEXT_FORBIDDEN.heading}</Typography>
          <Typography style={{ paddingTop: spacing(2) }}>
            {TEXT_FORBIDDEN.message} {TEXT_FORBIDDEN.email}
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}
