import React, { FunctionComponent } from 'react'
import { Form } from 'react-final-form'

import { RouteComponentProps } from '@reach/router'
import { Box, Grid, Typography } from '@material-ui/core'

import { Preloader } from 'components'
import { LoginFormValues } from 'utils'
import { Layout } from 'components/Layout'
import { ClientRoutePaths } from 'utils/consts'
import { LogInButton } from 'components/buttons'
import { UserEmailField } from 'components/forms/commonFields/UserEmailField'

import { useRedirectIfLoggedIn } from 'utils/useRedirectIfLoggedIn'
import { useSubmitLogin } from './useSubmitLogin'
import { MessageLoginConfirmation } from './MessageLoginConfirmation'

export const TEXT_LOGIN_FORM = {
  heading: 'Log in',
}

export const LoginForm: FunctionComponent<RouteComponentProps> = () => {
  const submitHandler = useSubmitLogin()

  const { isLoading } = useRedirectIfLoggedIn(ClientRoutePaths.Index)

  if (isLoading) return <Preloader />

  return (
    <Layout>
      <Form<LoginFormValues> onSubmit={submitHandler}>
        {({ handleSubmit, submitting, invalid, submitSucceeded, values }) =>
          submitSucceeded ? (
            <MessageLoginConfirmation email={values.email} />
          ) : (
            <>
              <Typography variant="h4">{TEXT_LOGIN_FORM.heading}</Typography>
              <Box marginTop={2}>
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container alignItems="center" spacing={2}>
                    <UserEmailField />
                    <Grid item xs={12}>
                      <LogInButton
                        loading={submitting}
                        disabled={submitting || invalid}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </>
          )
        }
      </Form>
    </Layout>
  )
}
