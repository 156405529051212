import { KeyboardEvent } from 'react'
import { useNotifications } from 'utils'
import { useQueryClient } from 'react-query'
import { useBoolean, useInput } from 'react-hanger/array'

import { AdminAPIRoutePaths } from 'utils/consts'
import { useManagePartners } from '../../ManagePartnersContext'

const TEXT_useAddPartnerHandler = {
  success: 'partner added',
  error: "can't add partner",
}

export const useAddPartnerHandler = () => {
  const [loading, loadingActions] = useBoolean(false)
  const { addPartner } = useManagePartners()
  const [[value], inputActions] = useInput()
  const { toastSuccess, toastError } = useNotifications()
  const queryClient = useQueryClient()

  const submitHandler = async () => {
    loadingActions.setTrue()

    try {
      if (value.split('@').length === 2) {
        await addPartner({ email: value })
        await queryClient.invalidateQueries(
          AdminAPIRoutePaths.RequestForInformationPendingUsers,
        )

        toastSuccess(TEXT_useAddPartnerHandler.success)
      } else {
        await addPartner({ domain_name: value })
        await queryClient.invalidateQueries(
          AdminAPIRoutePaths.RequestForInformationPendingUsers,
        )

        toastSuccess(TEXT_useAddPartnerHandler.success)
      }

      inputActions.clear()
    } catch (e) {
      toastError(TEXT_useAddPartnerHandler.error)
    }

    loadingActions.setFalse()
  }
  const keyPressHandler = async ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (value.length && key === 'Enter') {
      await submitHandler()
    }
  }

  return { submitHandler, keyPressHandler, value, inputActions, loading }
}
