import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useForm } from 'react-final-form'

import { AccountType } from 'utils'
import { composeValidators, exactValueLength, mustBeNumber } from 'utils/formValidators'
import { AuthorisedPushPaymentFormFieldsKeys } from 'utils/types'
import { RequestForInformationFormFieldsKeys } from 'pages/RequestForInformation/types'
import { CardNumberField } from 'pages/CardNotPresent/CardNotPresentForm/fields/CardNumberField'
import { TextInput } from './TextInput'
import { getFieldNameByAccountType, getSelectedAccountTypeValidators } from './helpers'

export const TEXT_AccountNumberField = {
  [AuthorisedPushPaymentFormFieldsKeys.IBAN]: 'Account IBAN',
  [AuthorisedPushPaymentFormFieldsKeys.AccountNumber]: 'Account Number',
  [AuthorisedPushPaymentFormFieldsKeys.SortCode]: 'Sort Code',
  [AuthorisedPushPaymentFormFieldsKeys.WalletRef]: 'Reference number',
  [RequestForInformationFormFieldsKeys.CardNumber]: 'Card number',
}

export const AccountNumberField = () => {
  const { getFieldState } = useForm()
  const accountTypeFieldState = getFieldState(
    AuthorisedPushPaymentFormFieldsKeys.AccountType,
  )

  if (!accountTypeFieldState?.value) return null

  const { value: selectedAccountType } = accountTypeFieldState
  const fieldName = getFieldNameByAccountType(selectedAccountType)
  const placeholder = TEXT_AccountNumberField[fieldName]
  const isSCANSelected = selectedAccountType === AccountType.SCAN
  const isSCardNumberSelected = selectedAccountType === AccountType.CardNumber

  if (isSCardNumberSelected) {
    return (
      <Box mt={2}>
        <CardNumberField />
      </Box>
    )
  }

  return (
    <Box marginTop={2} {...(isSCANSelected && { display: 'flex' })}>
      {isSCANSelected && (
        <Box mr={2}>
          <TextInput
            validate={composeValidators(mustBeNumber, exactValueLength(6))}
            name={AuthorisedPushPaymentFormFieldsKeys.SortCode}
            placeholder={
              TEXT_AccountNumberField[AuthorisedPushPaymentFormFieldsKeys.SortCode]
            }
          />
        </Box>
      )}

      <Grid item sm={6}>
        <TextInput
          fullWidth
          name={fieldName}
          placeholder={placeholder}
          validate={getSelectedAccountTypeValidators(selectedAccountType)}
        />
      </Grid>
    </Box>
  )
}
