import { AccountType, AuthorisedPushPaymentFormFieldsKeys } from 'utils'
import {
  composeValidators,
  maxValueLength,
  mustBeNumber,
  mustBeValidIBANCode,
  mustContainNoSpaces,
  requiredField,
} from 'utils/formValidators'
import { ACCOUNT_NUMBER_LENGTH } from 'utils/consts'
import { RequestForInformationFormFieldsKeys } from 'pages/RequestForInformation/types'

export const getSelectedAccountTypeValidators = (selectedAccountType: AccountType) => {
  if (selectedAccountType === AccountType.IBAN) {
    return composeValidators(requiredField, mustContainNoSpaces, mustBeValidIBANCode)
  }

  return composeValidators(
    requiredField,
    mustContainNoSpaces,
    mustBeNumber,
    maxValueLength(ACCOUNT_NUMBER_LENGTH),
  )
}
export const getFieldNameByAccountType = (selectedAccountType: AccountType) => {
  switch (selectedAccountType) {
    case AccountType.IBAN:
      return AuthorisedPushPaymentFormFieldsKeys.IBAN
    case AccountType.SCAN:
      return AuthorisedPushPaymentFormFieldsKeys.AccountNumber

    case AccountType.WalletRef:
      return AuthorisedPushPaymentFormFieldsKeys.WalletRef

    case AccountType.CardNumber:
      return RequestForInformationFormFieldsKeys.CardNumber

    default:
      throw Error(`implementation for ${selectedAccountType} is missing`)
  }
}
