import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0666EB',
      dark: '#1775f9',
    },
    secondary: {
      main: '#e950a4',
      dark: '#ec6bb2',
    },
  },
})
