import React from 'react'
import { Link } from '@reach/router'
import { Box, Button } from '@material-ui/core'

import { HeaderContent } from 'components'
import { ClientRoutePaths } from 'utils/consts'

import { useFormState } from 'react-final-form'
import { TEXT_IDENTIFICATION_STEP } from '../IdentificationStep/texts'
import { RequestForInformationFormStepsValues } from '../requestForInformationFormSteps'

const RFI_ID_TEXT_KEY = ':rfi_id:'

export const TEXT = {
  heading: `Thank you very much for submitting your request via our portal. Your request has been submitted successfully, one of our analysts will review your request as soon as possible (normally within 1 working day) and you’ll receive the outcome via email to guide you through the next step.

I’ll also receive a confirmation email shortly.`,
  paragraph: `Meanwhile, if you have any questions, please contact us at courtorders@revolut.com quoting the reference number: ${RFI_ID_TEXT_KEY}`,
}

export const RequestForInFormationSubmitStep = (): JSX.Element => {
  const {
    values: { rfi_id },
  } = useFormState<RequestForInformationFormStepsValues>()

  if (!rfi_id) return <div>No rfi id</div>

  return (
    <Box mt={4}>
      <HeaderContent
        heading={TEXT.heading}
        paragraph={TEXT.paragraph.replace(RFI_ID_TEXT_KEY, rfi_id)}
      />
      <Box my={4}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={ClientRoutePaths.Login}
        >
          {TEXT_IDENTIFICATION_STEP.backToPortalButtonText}
        </Button>
      </Box>
    </Box>
  )
}
