import React from 'react'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import { RecordVoiceOverOutlined } from '@material-ui/icons'
import { PartnerItem } from 'utils/types'

type DomainDailyLimitIconProps = Pick<PartnerItem, 'absolute_daily_limit'>

const TEXT_DomainDailyLimitIcon = {
  defaultText: 'undefined',
}

export const DomainDailyLimitIcon = ({
  absolute_daily_limit,
}: DomainDailyLimitIconProps): JSX.Element => (
  <Grid container spacing={1}>
    <Grid item>
      <Tooltip title="absolute daily limit">
        <RecordVoiceOverOutlined />
      </Tooltip>
    </Grid>
    <Grid item>
      <Typography>
        {absolute_daily_limit || TEXT_DomainDailyLimitIcon.defaultText}
      </Typography>
    </Grid>
  </Grid>
)
