/* istanbul ignore file */
import * as Sentry from '@sentry/react'

import { BrowserTracing } from '@sentry/tracing'

import { Dedupe, ExtraErrorData } from '@sentry/integrations'

export const initSentry = () =>
  Sentry.init({
    dsn: 'https://9eff6d3af7d24dad900ccec485f5b735@o104379.ingest.sentry.io/5421357',
    release: process.env.REACT_APP_RELEASE_TAG,
    environment: window.location.origin.endsWith('.com') ? 'production' : 'development',
    integrations: [
      new BrowserTracing({
        _metricOptions: {
          _reportAllChanges: true,
        },
      }),
      new Dedupe(),
      new ExtraErrorData({ depth: 6 }),
    ],

    tracesSampleRate: 1,
  })
