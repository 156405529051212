import faker from 'faker'
import { Factory } from 'miragejs'
import times from 'lodash/times'

import { PartnerEmail, PartnerItem } from '../../types'
import { FactoryMap } from './types'

export const createMockPartnerEmail = (
  domain = 'revolut.com',
  emailName?: string,
): PartnerEmail => ({
  id: faker.random.alphaNumeric(6),
  email: emailName
    ? `${emailName}@${domain}`
    : faker.internet.email(undefined, undefined, domain),
})

export const createPartnerMockFactory = () =>
  Factory.extend<FactoryMap<PartnerItem>>({
    id: () => faker.random.alphaNumeric(6),
    domain_name: () => faker.internet.domainName(),
    is_whole_domain_whitelisted: () => faker.random.boolean(),
    absolute_daily_limit: () => (faker.random.boolean() ? faker.random.number(30) : null),
    whitelisted_emails() {
      return times((this.absolute_daily_limit as unknown as number) || 5, () =>
        createMockPartnerEmail(this.domain_name as unknown as string),
      )
    },
  })
