import { ClientRoutePaths } from 'utils/consts'
import { useAuthService } from 'services/CerberusAuth'

import { navigate } from '@reach/router'

import { FormCardProps } from './FormCard'

export const TEXT_FORMS_LIST = {
  heading:
    'By using these forms to communicate with Revolut you help us to investigate your case quickly and precisely.',
  requestAccessToForms:
    'Looks like there is no forms assigned to you, please request access',
  formCards: [
    {
      title: 'Card-Not-Present Fraud',
      bodyText:
        "Use this form to report fraud when your client's card has been compromised and used to top-up Revolut's account.",
    },
    {
      title: 'Authorised Push Payment (SCAM)',
      bodyText: 'Use this form to report Authorised Push Payment (APP/SCAM) fraud.',
    },
    {
      title: 'Request For Information',
      bodyText: "Use this form to request Revolut customer's information and documents.",
    },
  ],
}

const {
  formCards: [CardNotPresent, AuthorisedPushPayment, RequestForInformation],
} = TEXT_FORMS_LIST

export const useFormCardsList = () => {
  const {
    access: { hasAccessToFraud, hasAccessToRfi },
  } = useAuthService()

  const formCards: Array<FormCardProps> = []

  if (hasAccessToFraud) {
    formCards.push(
      {
        ...CardNotPresent,
        action: {
          handler: () => navigate(ClientRoutePaths.CardNotPresentForm),
        },
      },
      {
        ...AuthorisedPushPayment,
        action: {
          handler: () => navigate(ClientRoutePaths.AuthorisedPushPaymentForm),
        },
      },
    )
  }
  if (hasAccessToRfi) {
    formCards.push({
      ...RequestForInformation,
      action: {
        handler: () => navigate(ClientRoutePaths.RequestForInformation),
      },
    })
  }

  return formCards
}
