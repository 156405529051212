import React, { ChangeEvent, FunctionComponent } from 'react'
import { useField } from 'react-final-form'
import { format } from 'date-fns'
import { FormControl, TextField, Tooltip } from '@material-ui/core'

import { getCurrentDate } from 'utils'
import {
  composeValidators,
  exactValueLength,
  mustBeDateInput,
  mustBeNoFutureDate,
  requiredField,
} from 'utils/formValidators'

type TransactionDateFieldFallBackProps = {
  name: string
  helperText?: string
  label: string
  initialValue: string
}

const currentIsoDateTime = format(getCurrentDate(), "yyyy-MM-dd'T'HH:mm")
const ieDateFormat = /(\d\d\d\d).?(\d\d).?(\d\d)\s?(\d\d):?(\d\d)/
const formatPattern = '$1-$2-$3 $4:$5'

export const TransactionDateFieldFallBack: FunctionComponent<TransactionDateFieldFallBackProps> =
  ({ name, helperText, label, initialValue }) => {
    const { meta, input } = useField(name, {
      initialValue,
      validate: composeValidators(
        requiredField,
        mustBeDateInput,
        exactValueLength(16),
        mustBeNoFutureDate(currentIsoDateTime),
      ),
    })
    const { onChange: fieldInputChange, value: formFieldValue, ...inputProps } = input
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
        .replace(ieDateFormat, formatPattern)
        .replace(/\s/, 'T')

      fieldInputChange({ target: { value } })
    }

    return (
      <Tooltip
        open={meta.active && Boolean(meta.error)}
        title={meta.error}
        placement="top-end"
      >
        <FormControl fullWidth>
          <TextField
            autoComplete="off"
            label={label}
            helperText={helperText}
            variant="outlined"
            value={formFieldValue.replace('T', '').replace(ieDateFormat, formatPattern)}
            onChange={onChangeHandler}
            {...inputProps}
          />
        </FormControl>
      </Tooltip>
    )
  }
