import React, { useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Box, Grid } from '@material-ui/core'

import { BackToPortalButton, SubmitFormButton } from 'components/buttons'
import { HeaderContent } from 'components'
import { AccountType, AuthorisedPushPaymentFormFieldsKeys } from 'utils/types'
import {
  AccountNumberField,
  AccountTypeField,
  BeneficiaryField,
  DescriptionField,
  Transactions,
} from 'components/forms/commonFields'
import { useAuthorisedPushPaymentFormSubmit } from './useAuthorisedPushPaymentFormSubmit'
import { AuthorisedPushPaymentFormConfirmation } from './Confirmation'
import { affirmationsTexts } from './affirmationsTexts'
import { AuthorisedPushPaymentFormAffirmations } from './AuthorisedPushPaymentFormAffirmations'

export const TEXT_AuthorisedPushPaymentForm = {
  mainParagraph: `Please use this form to report <strong>Authorised Push Payment (APP) </strong> fraud to Revolut.`,
  accountTypeFieldLabel: 'Which account do you want to report?',
  subParagraph:
    'By using this form, you’ll help us to identify the fraudster much faster and increase the chance of refunding your losses.',
  messages: {
    success: 'Form submitted!',
    error: "can't submit AuthorisedPushPayment form error:",
  },
}

export const AuthorisedPushPaymentForm = (): JSX.Element => {
  const [referenceNumber, setReferenceNumber] = useState<string>()
  const submitAppForm = useAuthorisedPushPaymentFormSubmit()

  return (
    <>
      {!referenceNumber && (
        <HeaderContent
          heading={TEXT_AuthorisedPushPaymentForm.mainParagraph}
          paragraph={TEXT_AuthorisedPushPaymentForm.subParagraph}
        />
      )}
      <Box mb={3}>
        <Grid item xs={12}>
          {referenceNumber ? (
            <>
              <AuthorisedPushPaymentFormConfirmation referenceNumber={referenceNumber} />
              <Box marginTop={7}>
                <Grid item xs="auto" sm={5} md={3}>
                  <BackToPortalButton />
                </Grid>
              </Box>
            </>
          ) : (
            <Form
              initialValues={{
                [AuthorisedPushPaymentFormFieldsKeys.Affirmations]: affirmationsTexts,
              }}
              onSubmit={async formValues => {
                const refNumber = await submitAppForm(formValues)

                if (refNumber) {
                  setReferenceNumber(refNumber)
                }
              }}
              mutators={{ ...arrayMutators }}
            >
              {({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <AccountTypeField
                    label={TEXT_AuthorisedPushPaymentForm.accountTypeFieldLabel}
                    exclude={[AccountType.CardNumber]}
                  />
                  <AccountNumberField />
                  <Box marginTop={4}>
                    <Transactions>
                      <BeneficiaryField />
                      <Grid container>
                        <DescriptionField />
                      </Grid>
                    </Transactions>
                  </Box>
                  <Box marginTop={2}>
                    <AuthorisedPushPaymentFormAffirmations />
                  </Box>
                  <SubmitFormButton disabled={submitting || invalid} />
                </form>
              )}
            </Form>
          )}
        </Grid>
      </Box>
    </>
  )
}
