import { PartnerItem, useNotifications } from 'utils'

import { UpdatePartnerVariables, useManagePartners } from '../../ManagePartnersContext'

const TEXT_usePartnerCardFormSubmit = {
  success: 'partner updated successfully',
  error: 'partner update error',
}

export const usePartnerCardFormSubmit = () => {
  const { toastError, toastSuccess } = useNotifications()
  const { updatePartnerById } = useManagePartners()

  return async (formValues: PartnerItem) => {
    const {
      absolute_daily_limit,
      whitelisted_emails = [],
      domain_name,
      is_whole_domain_whitelisted,
      id,
    } = formValues
    const payload: UpdatePartnerVariables = {
      domain_name,
      is_whole_domain_whitelisted,
      absolute_daily_limit: absolute_daily_limit || null,
      whitelisted_emails: whitelisted_emails.map(({ email }) => email),
    }

    try {
      await updatePartnerById(id, payload)
      // resetFormValues(payload)
      toastSuccess(TEXT_usePartnerCardFormSubmit.success)
    } catch {
      toastError(TEXT_usePartnerCardFormSubmit.error)
    }
  }
}
