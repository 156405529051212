/* istanbul ignore file */
import React from 'react'
import { Router } from '@reach/router'

import { AdminRoute, PrivateRoute } from 'components'
import { AppProviders } from 'components/AppProviders'
import { AdminClientRoutePaths, ClientRoutePaths } from 'utils/consts'

import { Home } from './Home'
import { Login } from './Login'
import { Admin } from './admin'
import { Reports } from './Reports'
import { FormList } from './FormList'
import { Forbidden } from './Forbidden'
import { NotFoundPage } from './notFoundPage'
import { CardNotPresent } from './CardNotPresent'
import { RequestForInformation } from './RequestForInformation'
import { AuthorisedPushPaymentForm } from './AuthorisedPushPayment'
import { RequestForInformationReview } from './RequestForInformation/RequestForInformationReview'
import { RequestForInformationDownloads } from './RequestForInformation/RequestForInformationDownloads'

export const Routes = () => (
  <Router basepath={ClientRoutePaths.Root}>
    <AppProviders path={ClientRoutePaths.Root}>
      <Home path={ClientRoutePaths.Root} />
      <Login path={ClientRoutePaths.Login} />
      <AdminRoute path={`${AdminClientRoutePaths.Root}/*`} component={Admin} />
      <PrivateRoute
        path={ClientRoutePaths.Index}
        component={FormList}
        access={['hasAccessToRfi', 'hasAccessToFraud']}
      />
      <PrivateRoute
        path={ClientRoutePaths.CardNotPresentForm}
        component={CardNotPresent}
        access={['hasAccessToFraud']}
      />
      <PrivateRoute
        path={ClientRoutePaths.AuthorisedPushPaymentForm}
        component={AuthorisedPushPaymentForm}
        access={['hasAccessToFraud']}
      />
      <PrivateRoute
        path={ClientRoutePaths.RequestForInformation}
        component={RequestForInformation}
        access={['hasAccessToRfi']}
      />
      <PrivateRoute
        path={ClientRoutePaths.RequestForInformationDownloads}
        component={RequestForInformationDownloads}
        access={['hasAccessToRfi']}
      />
      <PrivateRoute
        access={['hasAccessToRfi', 'hasAccessToFraud']}
        path={ClientRoutePaths.Reports}
        component={Reports}
      />
      <RequestForInformationReview path={ClientRoutePaths.RequestForInformationReview} />
      <Forbidden path="/403" />
      <NotFoundPage path="/*" />
    </AppProviders>
  </Router>
)
