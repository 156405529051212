import { cerberusRequest } from 'services/Cerberus'
import { AdminAPIRoutePaths, APIRoutePaths } from 'utils/consts'

export enum AuthQueriesKeys {
  CheckAdminAccess = 'checkAdminAccess',
}

export type ValidateUrlTokenQueryResponse = {
  id: string
  first_name?: string
  last_name?: string
  email?: string
  permissions: string[]
}

export const validateUrlTokenQuery = (token: string) =>
  cerberusRequest<{ token: string }>({
    data: { token },
    url: APIRoutePaths.Login,
  })

export const validateCookieTokenQuery = () =>
  cerberusRequest<undefined, ValidateUrlTokenQueryResponse>({
    url: APIRoutePaths.WhoAmI,
    method: 'GET',
  })

export const checkAdminAccessQuery = () =>
  cerberusRequest<undefined, boolean>({
    url: AdminAPIRoutePaths.CheckAdminAccess,
    method: 'GET',
  })
