import React, { PropsWithChildren } from 'react'

import { Container, Box } from '@material-ui/core'

import styled from 'styled-components'

import { Header, Footer } from 'components'
import './LayoutStyle.css'

const StyledContainer = styled.div`
  height: inherit;
`

export const Layout = ({ children }: PropsWithChildren<unknown>) => (
  <StyledContainer>
    <Header />
    <Container maxWidth="md">
      <Box minHeight="79vh">
        <main>{children}</main>
      </Box>
      <Footer />
    </Container>
  </StyledContainer>
)
