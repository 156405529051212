import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@material-ui/core'

import { RemoveTransactionButton } from 'components/buttons'
import { ExpandMore } from '@material-ui/icons'

import { CurrencyWithAmountField } from 'components/forms/commonFields'
import { useTransactionContext } from './TransactionContext'
import { TransactionDateField } from './TransactionDateField'

export const TEXT_TransactionCard = {
  heading: 'Transaction #',
}

export const TransactionCard = () => {
  const { fields, index, additionalFields } = useTransactionContext()

  if (!fields) return null
  const fieldsCount = fields.length ?? 0

  return (
    <Box my={4}>
      <Accordion defaultExpanded={Boolean(fieldsCount < 3) || index === fieldsCount - 1}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box display="flex" alignItems="center">
            <Typography>{`${TEXT_TransactionCard.heading}${index + 1}`}</Typography>
            {fieldsCount > 1 && (
              <Box ml={2}>
                <RemoveTransactionButton />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <TransactionDateField />
            <Box my={4}>
              <CurrencyWithAmountField />
            </Box>
            {additionalFields}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
