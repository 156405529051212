import React, { FunctionComponent } from 'react'
import { Grid } from '@material-ui/core'
import { TextInput } from 'components/forms/commonFields/TextInput'
import { composeValidators, mustBeEmail, requiredField } from 'utils/formValidators'

export const TEXT_LOGIN_FORM = {
  emailInputLabel: 'E-mail',
}

export const UserEmailField: FunctionComponent = () => {
  return (
    <Grid item xs={12}>
      <TextInput
        autoComplete="on"
        fullWidth
        name="email"
        placeholder={TEXT_LOGIN_FORM.emailInputLabel}
        validate={composeValidators(requiredField, mustBeEmail)}
      />
    </Grid>
  )
}
