import { Nullable, PartnerEmail, PartnerItem, PartnerItemsList, UUID } from 'utils/types'
import { AxiosPromise } from 'axios'

export type UpdatePartnerVariables = Omit<PartnerItem, 'id' | 'whitelisted_emails'> & {
  whitelisted_emails: string[]
}

export enum UserStatus {
  Created = 'CREATED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type PendingPartner = {
  id: UUID
  comment: string
  created_at: string
  updated_at: string
  status: UserStatus
  partner_email: string
}

export type AddPartnerHandlerResponse = PendingPartner & {
  created_by: PartnerEmail
  reviewed_by: PartnerEmail
  reviewed_at: Nullable<string>
}

export type AddPartnerHandler = (
  payload: Pick<PartnerItem, 'domain_name'> | Pick<PartnerEmail, 'email'>,
) => AxiosPromise<AddPartnerHandlerResponse>

export type DeletePartnerByIdHandler = (partnerId: PartnerItem['id']) => Promise<void>
export type UpdatePartnerByIdHandler = (
  partnerId: PartnerItem['id'],
  variables: UpdatePartnerVariables,
) => Promise<void>

export type ManagePartnersContextType = {
  loading: boolean
  partners: PartnerItemsList
  addPartner: AddPartnerHandler
  deletePartnerById: DeletePartnerByIdHandler
  updatePartnerById: UpdatePartnerByIdHandler
}
