import querystring from 'querystring'

import { navigate } from '@reach/router'
import { isProd } from 'utils/env'

const DEV_IDAVE_HOST = 'https://id.revolut.codes'
const DEV_IDAVE_APP_ID = '509597a4-34a3-4eec-80b5-0ace1ed82866'

const PROD_IDAVE_HOST = 'https://id.revolut.com'
const PROD_IDAVE_APP_ID = '85774994-77c2-4e3c-8880-579b4f218544'

export const redirectToSignIn = (reauthenticate = false) => {
  const authEndpoint = isProd() ? PROD_IDAVE_HOST : DEV_IDAVE_HOST
  const appId = isProd() ? PROD_IDAVE_APP_ID : DEV_IDAVE_APP_ID

  const params = querystring.stringify({
    redirect_uri: window.location.toString(),
    response_type: 'code',
    client_id: appId,
    auth_type: reauthenticate ? 'reauthenticate' : 'default',
  })

  const url = `${authEndpoint}/signin?${params}`

  return navigate(url)
}
