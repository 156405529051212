import React from 'react'
import { TableHead, TableRow, ThemeProvider } from '@material-ui/core'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import { common, grey } from '@material-ui/core/colors'

import { EnhancedTableProps, HeadCell } from './types'
import { TableHeaderCell } from './TableHeaderCell'

const headCells: ReadonlyArray<HeadCell> = [
  { id: 'id', label: 'Reference ID' },
  { id: 'description', label: 'Description' },
  { id: 'created_at', label: 'Created Date' },
  { id: 'type', label: 'Type' },
  { id: 'status', label: 'State' },
]

const tableHeadTheme = createMuiTheme({
  palette: {
    text: {
      primary: common.white,
      secondary: grey['400'],
    },
  },
})

const TableRowStyled = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey['900'],
  },
}))(TableRow)

export const TableHeaders = ({
  order,
  orderBy,
  onRequestSort,
}: Omit<EnhancedTableProps<unknown>, 'classes'>) => (
  <ThemeProvider theme={tableHeadTheme}>
    <TableHead>
      <TableRowStyled>
        {headCells.map(({ id, label }, index) => (
          <TableHeaderCell
            key={id}
            id={id}
            label={label}
            orderBy={orderBy}
            order={order}
            onRequestSort={onRequestSort}
            boxProps={{ pl: index === 0 ? 2 : 0 }}
          />
        ))}
      </TableRowStyled>
    </TableHead>
  </ThemeProvider>
)
