import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'

import { PrivateRoutePayload } from 'utils'
import { Layout, Preloader } from 'components/index'

import { usePrivateRoute } from './usePrivateRoute'
import { UserAccessRights } from '../../services/security'

type PrivateRouteProps = RouteComponentProps & {
  component: FunctionComponent<PrivateRoutePayload>
  access: Array<keyof UserAccessRights>
}

export const TEXT_PRIVATE_ROUTE = {
  preloaderTitle: 'Loading page',
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  location,
  access,
  ...rest
}) => {
  const { isLoading, isVisible } = usePrivateRoute(access)

  if (isLoading) {
    return <Preloader wrapper={{ title: TEXT_PRIVATE_ROUTE.preloaderTitle }} />
  }

  if (!isVisible) {
    return null
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  )
}
