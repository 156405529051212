/* istanbul ignore file */
import React, { FunctionComponent } from 'react'
import { QueryClient, QueryCache, QueryClientProvider, MutationCache } from 'react-query'

import { Routes } from './Routes'

const queryCache = new QueryCache()
const mutationCache = new MutationCache()

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: false },
  },
})

export const App: FunctionComponent = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </>
  )
}
