import React, { FunctionComponent } from 'react'
import { Typography } from '@material-ui/core'

import { InnerHTML } from 'components'

export const TEXT_LOGIN_CONFIRMATION = {
  message: (email: string) =>
    `We have sent you an email to <b>${email}</b>* with the access link, please check your inbox and click on the access link to continue. Please note that the link will expire in 2 hours.`,
  caption: '*please check your spam folder in case you can’t find the email.',
}

type LoginConfirmationProps = {
  email: string
}
export const MessageLoginConfirmation: FunctionComponent<LoginConfirmationProps> = ({
  email,
}) => (
  <>
    <Typography paragraph>
      <InnerHTML as="span" html={TEXT_LOGIN_CONFIRMATION.message(email)} />
    </Typography>
    <Typography variant="caption">{TEXT_LOGIN_CONFIRMATION.caption}</Typography>
  </>
)
