import React from 'react'
import { useFormState } from 'react-final-form'

import { SubmitFormButton, TEXT_SUBMIT_BUTTON } from 'components/buttons'

type RequestForInformationFormStepperButtonProps = {
  text?: string
  progress: boolean
  disabled?: boolean
  onClick: <FormValuesType>(values: FormValuesType) => void
}

export const TEXT_REQUEST_FOR_INFORMATION_STEPPER_BUTTON = 'Next'

export const RequestForInformationFormStepperButton = ({
  text = TEXT_REQUEST_FOR_INFORMATION_STEPPER_BUTTON,
  progress,
  onClick,
  disabled,
}: RequestForInformationFormStepperButtonProps): JSX.Element => {
  const { invalid, values, submitting } = useFormState()

  return (
    <SubmitFormButton
      type="button"
      text={text}
      title={TEXT_SUBMIT_BUTTON}
      loading={progress || submitting}
      onClick={() => onClick(values)}
      disabled={disabled || invalid || progress}
    />
  )
}
