import React from 'react'
import { useField } from 'react-final-form'
import {
  Box,
  Grid,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'

import { TextInput } from 'components/forms/commonFields'

import {
  RequestForInformationApprovalDecision,
  RequestForInformationReviewFormState,
} from './types'

export const ApprovalDecision = (): JSX.Element => {
  const { input } = useField<RequestForInformationReviewFormState['decision']>(
    'decision',
    {
      type: 'RadioGroup',
      initialValue: RequestForInformationApprovalDecision.Reject,
    },
  )

  return (
    <>
      <RadioGroup {...input}>
        <Grid container>
          {Object.values(RequestForInformationApprovalDecision).map(fieldName => (
            <Grid item key={fieldName}>
              <FormControlLabel control={<Radio />} value={fieldName} label={fieldName} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
      {input.value === RequestForInformationApprovalDecision.Reject && (
        <Box mt={4}>
          <Grid item md={6}>
            <FormControl fullWidth>
              <TextInput<RequestForInformationReviewFormState>
                rows={6}
                multiline
                name="rejectionReason"
              />
              <FormHelperText>
                rejection reason will be sent to our partner
              </FormHelperText>
            </FormControl>
          </Grid>
        </Box>
      )}
    </>
  )
}
