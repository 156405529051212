import { getFullApiRoutePath } from 'utils/url'
import { APIRoutePaths } from 'utils/consts'
import { CerberusSubmitReturn } from 'utils/types'

import { mockReferenceNumber } from 'utils/tests'
import { MockServerEndpointHandler } from './types'

export const authorisedPushPaymentHandlers: MockServerEndpointHandler = ({ post }) => {
  post(
    getFullApiRoutePath(APIRoutePaths.SubmitAuthorisedPushPaymentRequest),
    (): CerberusSubmitReturn => ({
      reference_number: mockReferenceNumber,
    }),
  )
}
