import React, { PropsWithChildren } from 'react'
import { RouteComponentProps } from '@reach/router'
import { ThemeProvider } from '@material-ui/core'
import { ConfirmProvider } from 'material-ui-confirm'
import { ToastProvider } from 'react-toast-notifications'

import { theme } from 'theme/theme'
import { CerberusAuthProvider } from 'services/CerberusAuth'

export const AppProviders = ({
  children,
}: PropsWithChildren<RouteComponentProps>): JSX.Element => (
  <ThemeProvider theme={theme}>
    <ToastProvider>
      <CerberusAuthProvider>
        <ConfirmProvider>{children}</ConfirmProvider>
      </CerberusAuthProvider>
    </ToastProvider>
  </ThemeProvider>
)
