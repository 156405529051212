import React, { FunctionComponent } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { InnerHTML } from './InnerHTML'

type HeaderContentProps = {
  heading: string
  paragraph?: string
}
export const HeaderContent: FunctionComponent<HeaderContentProps> = ({
  heading,
  paragraph,
}) => (
  <Box mb={3}>
    <Grid item xs={12}>
      <Typography paragraph>
        <InnerHTML as="span" html={heading} />
      </Typography>
      {paragraph && (
        <Typography paragraph>
          <InnerHTML as="span" html={paragraph} />
        </Typography>
      )}
    </Grid>
  </Box>
)
