import React, { FunctionComponent, useEffect } from 'react'
import { useQuery } from 'react-query'
import { RouteComponentProps } from '@reach/router'

import { Preloader } from 'components'
import { PrivateRoutePayload } from 'utils'
import { AuthQueriesKeys, checkAdminAccessQuery } from 'services/CerberusAuth/queries'
import { redirectToSignIn } from 'services/IDaveAuth'

type AdminRouteProps = RouteComponentProps & {
  component: FunctionComponent<PrivateRoutePayload>
}

export const TEXT_ADMIN_ROUTE = {
  preloaderTitle: 'Loading page',
}

export const AdminRoute: FunctionComponent<AdminRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const { isLoading, isFetched, isError } = useQuery(
    AuthQueriesKeys.CheckAdminAccess,
    checkAdminAccessQuery,
  )

  useEffect(() => {
    if (!isLoading && isFetched && isError) {
      redirectToSignIn()
    }
  }, [isLoading, isFetched, isError])

  if (isError) {
    return null
  }

  if (isLoading) {
    return <Preloader wrapper={{ title: TEXT_ADMIN_ROUTE.preloaderTitle }} />
  }

  return <Component {...rest} />
}
