import React, { FunctionComponent } from 'react'

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import {
  AccountCircleOutlined,
  DomainOutlined,
  RecordVoiceOverOutlined,
} from '@material-ui/icons'
import { PartnerItem } from 'utils'
import { useFormState } from 'react-final-form'
import { SwitchInput, TextInput } from 'components/forms/commonFields'
import { useEditContext } from './PartnerCardEditContext'

export const PartnerItemEditor: FunctionComponent = () => {
  const {
    values: { whitelisted_emails, is_whole_domain_whitelisted },
  } = useFormState<PartnerItem>()

  const { isEdit } = useEditContext()

  if (!isEdit) {
    return null
  }

  const editDomainNameField = !whitelisted_emails.length && (
    <ListItem>
      <ListItemIcon>
        <AccountCircleOutlined />
      </ListItemIcon>
      <ListItemText primary="domain name" />
      <ListItemSecondaryAction>
        <TextInput<PartnerItem> name="domain_name" />
      </ListItemSecondaryAction>
    </ListItem>
  )

  return (
    <>
      <List>
        {editDomainNameField}
        <ListItem>
          <ListItemIcon>
            <RecordVoiceOverOutlined />
          </ListItemIcon>
          <ListItemText primary="Daily limit" />
          <ListItemSecondaryAction>
            <TextInput<PartnerItem>
              name="absolute_daily_limit"
              placeholder="undefined"
              variant="standard"
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <DomainOutlined />
          </ListItemIcon>
          <ListItemText primary="whitelist whole domain" />
          <ListItemSecondaryAction>
            <SwitchInput<PartnerItem>
              name="is_whole_domain_whitelisted"
              initialValue={is_whole_domain_whitelisted}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Box my={2}>
        <Divider />
      </Box>
    </>
  )
}
