/* istanbul ignore file */ // POS will be removed after approval
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useField, useForm, useFormState } from 'react-final-form'
import { cond, filter, flow, forEach, get, map, pick, isError } from 'lodash/fp'

import { cerberusRequest } from 'services/Cerberus'
import { redirectToSignIn } from 'services/IDaveAuth'
import { setApiRoutePathDynamicKey } from 'utils/url'
import { parseApiCustomError } from 'utils/parseApiCustomError'
import { APIRoutePaths, RoutePathsDynamicKeys, CommonErrorMessages } from 'utils/consts'
import { MutationErrorType, RequestForInformationDocument, useNotifications } from 'utils'

import {
  isAxiosError,
  isStatusCustomError,
  isStatusForbidden,
  isStatusUnauthorised,
} from 'utils/axiosErrors'
import { RequestForInformationFormStepsValues } from '../requestForInformationFormSteps'

type UseRequestDocumentsMutationVariables = Array<
  Omit<RequestForInformationDocument, 'name'>
>
type UseRequestDocumentsMutationReturnType = AxiosResponse<
  { reference: number } | undefined
>

export const useRequestDocumentsMutation = () => {
  const {
    input: { onChange: setReferenceId },
  } = useField('rfi_id')
  const { submit } = useForm()
  const { toastError } = useNotifications()
  const { values } = useFormState<RequestForInformationFormStepsValues>()

  const { mutate: requestDocuments } = useMutation<
    UseRequestDocumentsMutationReturnType,
    MutationErrorType,
    UseRequestDocumentsMutationVariables
  >(
    data => {
      if (!values.rfi_id)
        throw new Error(CommonErrorMessages.RequestForInformationReferenceIdMissing)

      return cerberusRequest<UseRequestDocumentsMutationVariables>({
        url: setApiRoutePathDynamicKey(
          APIRoutePaths.RequestForInformationRequestDocs,
          RoutePathsDynamicKeys.RequestForInformationId,
          values.rfi_id,
        ),
        data,
      })
    },
    {
      onSuccess: data => {
        if (!data.data?.reference)
          throw new Error(CommonErrorMessages.RequestForInformationReferenceIdMissing)

        setReferenceId(data.data.reference)

        return submit()
      },
      onError: cond([
        [
          isStatusCustomError,
          flow(get('response.data'), parseApiCustomError, forEach(toastError)),
        ],
        [
          isAxiosError,
          cond([
            [
              isStatusForbidden,
              () => {
                toastError(CommonErrorMessages.HasNoAccess)
                redirectToSignIn()
              },
            ],
            [
              isStatusUnauthorised,
              () => {
                toastError(CommonErrorMessages.UserNotLoggedIn)
                redirectToSignIn()
              },
            ],
            [isError, flow(get('message'), toastError)],
          ]),
        ],
        [isError, flow(get('message'), toastError)],
      ]),
    },
  )

  const requestDocumentHandler = (
    docValues: Array<Omit<RequestForInformationDocument, 'name'>>,
  ) => {
    try {
      return requestDocuments(docValues)
    } catch ({ message }) {
      return toastError('request documents handler error:', message)
    }
  }

  return () =>
    flow(
      get('select_documents_affirmations'),
      filter(get('value')),
      map(pick(['type', 'start_date', 'end_date'])),
      requestDocumentHandler,
    )(values)
}
