import React from 'react'
import { Box } from '@material-ui/core'

import {
  AffirmationCheckBoxItem,
  AffirmationsListType,
} from 'components/forms/commonFields'

export const affirmationsTexts: AffirmationsListType = [
  {
    id: 'affirmation-1',
    value: false,
    text: 'I confirm that I have performed the necessary due diligence to verify the claim such as, but not limited to, requesting the reporter to file a police or equivalent report.',
    render: props => <AffirmationCheckBoxItem {...props} />,
  },
  {
    id: 'affirmation-2',
    value: false,
    text: 'I understand that if I do not provide an indemnity letter, indicating the account the funds should be returned to, I accept that Revolut will return the remaining funds to the originating account.',
    render: props => (
      <Box mt={2}>
        <AffirmationCheckBoxItem {...props} />
      </Box>
    ),
  },
]
