import { formatISO } from 'date-fns/fp'
import { cerberusRequest } from 'services/Cerberus'
import {
  CardNotPresentReport,
  CerberusCardNotPresentReport,
  CerberusCardNotPresentTransaction,
  useNotifications,
} from 'utils'
import { APIRoutePaths } from 'utils/consts'

const TEXT_CardNotPresentForm = {
  success:
    "Thank you! You have submitted the fraud report successfully! We'll be in touch shortly.",
  error: 'Unable to submit form:',
}

export const useCardNotPresentFormSubmit = () => {
  const { toastError } = useNotifications()

  return async ({ transactions, ...values }: CardNotPresentReport) => {
    const data: CerberusCardNotPresentReport = {
      ...values,
      transactions: transactions.map(
        ({ authorisation_code, ...transaction }): CerberusCardNotPresentTransaction => ({
          ...transaction,
          auth_ref_no: authorisation_code,
          timestamp: formatISO(new Date(transaction.timestamp).getTime()),
        }),
      ),
    }

    try {
      const {
        data: { reference_number },
      } = await cerberusRequest<
        CerberusCardNotPresentReport,
        { reference_number: string }
      >({ url: APIRoutePaths.CreateCardNotPresentRequest, data })

      return reference_number
    } catch (e) {
      const errorMessage = `${TEXT_CardNotPresentForm.error} ${JSON.stringify(e)}`

      toastError(errorMessage)

      return null
    }
  }
}
