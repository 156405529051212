/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React, { FunctionComponent } from 'react'
import { Link, RouteComponentProps, Router } from '@reach/router'
import { makeStyles, createStyles, Drawer, Theme, Button } from '@material-ui/core'
import { Add as IconAdd } from '@material-ui/icons'

import { AutomationItem } from './Automation'

const drawerWidth = 260

const useAutomationsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    drawerPaper: {
      padding: theme.spacing(3),
      width: drawerWidth,
    },
    addAutomationButton: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    content: {
      minHeight: '100vh',
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(4),
      marginLeft: drawerWidth,
    },
    listItem: {
      marginBottom: theme.spacing(1),
    },
  }),
)

const automationsLinks = [
  'automation-1',
  'automation-2',
  'automation-3',
  'automation-4',
  'automation-5',
]

export const Automations: FunctionComponent<RouteComponentProps> = (): JSX.Element => {
  const classnames = useAutomationsStyles()

  return (
    <div className={classnames.root}>
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{ paper: classnames.drawerPaper }}
      >
        <Button
          className={classnames.addAutomationButton}
          variant="outlined"
          color="secondary"
        >
          <IconAdd />
          Add automation
        </Button>

        {automationsLinks.map(automationLink => (
          <Button
            className={classnames.listItem}
            key={automationLink}
            variant="text"
            component={Link}
            to={automationLink}
          >
            {automationLink}
          </Button>
        ))}
      </Drawer>
      <main className={classnames.content}>
        <Router>
          <AutomationItem path=":id" />
        </Router>
      </main>
    </div>
  )
}
