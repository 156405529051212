/* istanbul ignore file */ // nothing to test here :)
import React from 'react'
import { Router, Redirect } from '@reach/router'

import { AdminClientRoutePaths } from 'utils/consts'
import { Automations } from './Automations'
import { ManagePartners } from './ManagePartners'

export const Admin = (): JSX.Element => (
  <Router>
    <Redirect
      from="/"
      to={`${AdminClientRoutePaths.Root}${AdminClientRoutePaths.ManagePartners}`}
      noThrow
    />
    <Automations path={`${AdminClientRoutePaths.Automations}/*`} />
    <ManagePartners path={AdminClientRoutePaths.ManagePartners} />
  </Router>
)
