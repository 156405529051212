import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Box, Grid } from '@material-ui/core'

import { RequestForInformationFormFieldsKeys } from './types'
import { uploadDocumentStepAffirmations } from './affirmationsWithDocuments'
import { RequestForInformationFormHeader } from './RequestForInformationFormHeader'
import { useRequestForInformationFormSteps } from './useRequestForInformationFormSteps'

export const TEXT_REQUEST_FOR_INFORMATION = {
  affirmationsHeading2: 'What information do you want to request?',
  accountTypeFieldLabel: 'Which account do you want to request information for?',
  messages: {
    success: 'Form submitted!',
    error: "can't submit RequestForInformation, form error:",
  },
}

export const RequestForInformation = (): JSX.Element => {
  const { stepRender, stepIndex, navigateToNextStep } =
    useRequestForInformationFormSteps()

  return (
    <Box mb={3}>
      <Grid item xs={12}>
        <Form
          onSubmit={navigateToNextStep}
          mutators={{ ...arrayMutators }}
          initialValues={{
            [RequestForInformationFormFieldsKeys.UploadDocumentAffirmations]:
              uploadDocumentStepAffirmations,
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RequestForInformationFormHeader stepIndex={stepIndex} />
              <Box ml={1.5}>{stepRender()}</Box>
            </form>
          )}
        </Form>
      </Grid>
    </Box>
  )
}
