import React from 'react'
import ReactDOM from 'react-dom'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './theme/global.css'

import { App } from './pages'
import { createMockServer } from './utils/mockServer'
import { initSentry } from './setupSentry'

if (process.env.REACT_APP_MOCK_SERVER) {
  createMockServer('development')
}

if (process.env.NODE_ENV === 'production') {
  initSentry()
}

ReactDOM.render(<App />, document.getElementById('mount'))
