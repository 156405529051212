/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'

import { RouteComponentProps } from '@reach/router'
import { AutomationForm } from './AutomationForm'

export const AutomationItem: FunctionComponent<RouteComponentProps> = (): JSX.Element => (
  <Box mb={8}>
    <Box mb={4}>
      <Typography variant="h5" component="h2">
        Automation: Name
      </Typography>
    </Box>

    <AutomationForm />
  </Box>
)
