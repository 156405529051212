import React from 'react'
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import { Field, useForm } from 'react-final-form'

import CurrenciesSelector from 'utils/currencies_selector'
import { CerberusCardNotPresentTransaction, currencies } from 'utils'
import { composeValidators, minValue, requiredField } from 'utils/formValidators'
import { useTransactionContext } from './Transactions/TransactionContext'

export const TEXT_CurrencyWithAmountField = {
  amount: 'Amount',
  placeholder: 'enter amount',
}

export const CurrencyWithAmountField = () => {
  const { field, index } = useTransactionContext()
  const form = useForm()
  const { values } = form.getState()

  const transaction: CerberusCardNotPresentTransaction = values.transactions[index]

  const Adornment = (
    <InputAdornment position="start">
      {currencies[transaction.currency].symbol}
    </InputAdornment>
  )

  return (
    <Grid item md={12}>
      <Grid container spacing={4}>
        <Grid item>
          <Field name={`${field}.currency`} validate={requiredField}>
            {({ input }) => (
              <CurrenciesSelector
                // @ts-expect-error TODO: refactor CurrenciesSelector
                currency={input.value}
                onCurrencyChange={input.onChange}
              />
            )}
          </Field>
        </Grid>

        <Grid item>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{TEXT_CurrencyWithAmountField.amount}</InputLabel>
            <Field
              name={`${field}.amount`}
              validate={composeValidators(requiredField, minValue(1))}
              type="number"
            >
              {({ input, meta }) => (
                <OutlinedInput
                  error={meta.touched && meta.invalid}
                  startAdornment={Adornment}
                  labelWidth={65}
                  placeholder={TEXT_CurrencyWithAmountField.placeholder}
                  {...input}
                />
              )}
            </Field>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
