import { random } from 'faker'
import { CerberusSubmitReturn } from '../types'

export const MOCK_VALID_IBAN = 'KW81CBKU0000000000001234560101'
export const mockReferenceNumber: CerberusSubmitReturn['reference_number'] = random
  .alphaNumeric(7)
  .toString()
export const MOCK_DATE_STRING = '2020-05-13T10:25'
export const mockJWTToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDEzODg0MzQsIm5iZiI6MTYwMTM4ODQzNCwianRpIjoiZDQyYTRlYTYtNWFjNC00M2VjLTlhYWQtMTY4YTRhZTU0YmViIiwiZXhwIjoxNjAxMzk1NjM0LCJpZGVudGl0eSI6Inl1cnkudHJveW5vdkByZXZvbHV0LmNvbSIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.ybmeY9rNxqAt66johA-lJG4bjibhkyU7_XppPmjdw6g'
export const mockJWTTokenWIthRfiDocument =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDEzODg0MzQsIm5iZiI6MTYwMTM4ODQzNCwianRpIjoiZDQyYTRlYTYtNWFjNC00M2VjLTlhYWQtMTY4YTRhZTU0YmViIiwiZXhwIjoxNjAxMzk1NjM0LCJpZGVudGl0eSI6InVzZXJAcmV2b2x1dC5jb20iLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MifQ.9fnUFTdPNoOyDnMrMsrROCqMfQasFH_AtlMsfg2qkUA'
