import { MockServerEndpointHandler } from './types'
import { userReportsHandlers } from './userReportsHandlers'
import { managePartnersHandlers } from './managePartnersHandlers'
import { cardNotPresentHandlers } from './cardNotPresentHandlers'
import { authenticationHandlers } from './authenticationHandlers'
import { requestForInformationHandlers } from './requestForInformationHandlers'
import { authorisedPushPaymentHandlers } from './authorisedPushPaymentHandlers'

export const endpointsHandlers: ReadonlyArray<MockServerEndpointHandler> = [
  userReportsHandlers,
  authenticationHandlers,
  managePartnersHandlers,
  cardNotPresentHandlers,
  requestForInformationHandlers,
  authorisedPushPaymentHandlers,
]
