import React, { FunctionComponent } from 'react'
import { PartnerItem } from 'utils/types'

import { CancelButton, DeleteButton, SaveButton } from '../buttons'
import { useManagePartners } from '../../ManagePartnersContext'

type PartnerCardEditActionsProps = {
  partnerId: PartnerItem['id']
  name: string
  disableEditMode: VoidFunction
  isFormChanged: boolean
}

export const TEXT_PartnerCardEditActions = {
  deleteConfirmationText: 'this wil permanently delete',
  cancelConfirmationText: 'this will discard all unsaved changes',
  submitButtonTitle: 'Save changes',
  deleteButtonTitle: 'Delete partner',
  cancelButtonTitle: 'Cancel',
}

export const PartnerCardEditActions: FunctionComponent<PartnerCardEditActionsProps> = ({
  partnerId,
  name,
  disableEditMode,
  isFormChanged,
}) => {
  const { deletePartnerById } = useManagePartners()

  return (
    <>
      {isFormChanged && (
        <SaveButton type="submit" title={TEXT_PartnerCardEditActions.submitButtonTitle} />
      )}
      <DeleteButton
        title={TEXT_PartnerCardEditActions.deleteButtonTitle}
        confirmationText={`${TEXT_PartnerCardEditActions.deleteConfirmationText} ${name}`}
        onClick={() => deletePartnerById(partnerId)}
      />
      <CancelButton
        title={TEXT_PartnerCardEditActions.cancelButtonTitle}
        confirmationText={TEXT_PartnerCardEditActions.cancelConfirmationText}
        skipConfirmation={!isFormChanged}
        onClick={disableEditMode}
      />
    </>
  )
}
