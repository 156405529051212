import { useEffect } from 'react'
import { navigate } from '@reach/router'

import { ClientRoutePaths } from './consts'
import { useCerberusAuth } from '../services/CerberusAuth'

export const useRedirectIfLoggedIn = (path: ClientRoutePaths) => {
  const { validateToken, ...query } = useCerberusAuth()

  useEffect(() => {
    validateToken({ throwOnError: true })
      .then(() => navigate(path))
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return query
}
