import React, { FunctionComponent } from 'react'
import { useConfirm } from 'material-ui-confirm'

import { TooltipButton, TooltipButtonProps } from './TooltipButton'

export type ConfirmationButtonProps = TooltipButtonProps & {
  confirmationText: string
  skipConfirmation?: boolean
}

export const ConfirmationButton: FunctionComponent<ConfirmationButtonProps> = ({
  title,
  confirmationText,
  skipConfirmation,
  onClick,
  ...props
}) => {
  const confirm = useConfirm()

  return (
    <TooltipButton
      title={title}
      onClick={async buttonEvent => {
        if (skipConfirmation) {
          return onClick && onClick(buttonEvent)
        }

        try {
          await confirm({
            description: confirmationText,
          })

          return onClick && onClick(buttonEvent)
        } catch (err) {
          if (err) {
            throw err
          }

          return null
        }
      }}
      {...props}
    />
  )
}
