import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { AccountCircleOutlined } from '@material-ui/icons'

import { PartnerItem } from 'utils/types'

type DomainNameIconProps = Pick<PartnerItem, 'domain_name'>
export const DomainNameIcon = ({ domain_name }: DomainNameIconProps): JSX.Element => (
  <Grid container spacing={1}>
    <Grid item>
      <AccountCircleOutlined />
    </Grid>
    <Grid item>
      <Typography>{domain_name}</Typography>
    </Grid>
  </Grid>
)
