/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { TextInput } from 'components/forms/commonFields'
import { requiredField } from 'utils/formValidators'

const TEXT_TEST_REFERENCE = {
  heading: 'Test reference ID',
}

export const TestReference = (): JSX.Element => {
  return (
    <>
      <Typography variant="h6" component="h3">
        {TEXT_TEST_REFERENCE.heading}
      </Typography>

      <Box my={5}>
        <TextInput name="referenceId" validate={requiredField} />
      </Box>
    </>
  )
}
