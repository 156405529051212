import qs from 'query-string'
import { useState } from 'react'
import jwtDecode from 'jwt-decode'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useLocation } from '@reach/router'
import { captureException } from '@sentry/react'

import { APIRoutePaths, CommonErrorMessages } from 'utils/consts'

import { validateCookieTokenQuery, validateUrlTokenQuery } from './queries'
import { invalidateAuthTokenMutation } from './mutations'
import { getUserAccess } from '../security'

const LOCAL_STORAGE_TOKEN_KEY = 'token'

export type TokenData = { identity: string; exp: number }

export const useAuthService = () => {
  const [tokenData, setTokenData] = useState(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

    if (!token) return null

    try {
      return jwtDecode<TokenData>(token)
    } catch (e) {
      captureException(e, {
        tags: {
          section: 'useAuthService: parse userInfoCookie',
        },
      })

      return null
    }
  })
  const { search } = useLocation()
  const { invalidateQueries } = useQueryClient()
  const { token } = qs.parse(search)

  // Mutations
  const { mutate: logOut } = useMutation(
    async () => {
      if (!token || typeof token !== 'string')
        throw new Error(CommonErrorMessages.AuthTokenIsMissing)

      return invalidateAuthTokenMutation(token)
    },
    {
      onSettled: () => invalidateQueries(APIRoutePaths.Login),
    },
  )

  // Queries
  const {
    data,
    isLoading,
    refetch: validateToken,
  } = useQuery(
    APIRoutePaths.Login,
    async () => {
      if (token && typeof token === 'string') {
        // setup cookies for users that came with token in URL
        await validateUrlTokenQuery(token)

        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
        setTokenData(jwtDecode<TokenData>(token))
      }

      return validateCookieTokenQuery()
    },
    {
      retry: false,
      enabled: false,
    },
  )

  const { permissions = [] } = data?.data || {}

  return {
    tokenData,
    access: getUserAccess(permissions),
    logOut,
    isLoading,
    validateToken,
  }
}
