import React from 'react'
import { CheckCircle, AddCircle, WatchLater } from '@material-ui/icons'

import { UserReportStatus } from './types'

type ReportStatusIconProps = {
  status: UserReportStatus
}

export const ReportStatusIcon = ({ status }: ReportStatusIconProps): JSX.Element => {
  switch (status) {
    case UserReportStatus.NEW:
      return <AddCircle />
    case UserReportStatus.Open:
      return <WatchLater />
    case UserReportStatus.Solved:
      return <CheckCircle />
    default:
      return status
  }
}
