import faker from 'faker'
import { Factory } from 'miragejs'
import startCase from 'lodash/startCase'

import {
  RequestForInformationSampleDocument,
  RequestForInformationSampleDocumentDocumentType,
} from 'utils/types'

import { FactoryMap } from './types'

const documentTypes = Object.values(RequestForInformationSampleDocumentDocumentType)

export const createMockRequestForInformationSampleDocumentFactory = () => {
  return Factory.extend<FactoryMap<RequestForInformationSampleDocument>>({
    is_timeframed: faker.random.boolean,
    type: () => documentTypes[faker.random.number(documentTypes.length - 1)],
    name() {
      return `${faker.finance.accountName()} ${faker.finance.transactionType()} ${startCase(
        this.type as unknown as string,
      )} ${
        // @ts-expect-error // by this moment ts treats is_timeframed as func but it's already a calculated value
        this.is_timeframed ? 'history' : ''
      } - ${faker.random.number(documentTypes.length)}`
    },
  })
}
