import React from 'react'

import { TimestampISO, UUID } from 'utils'
import { Order } from './tableHandlers'

export type HeadCell = Readonly<{
  id: keyof UserReport
  label: string
}>

export enum UserReportType {
  APP = 'APP',
  CNP = 'CNP',
  RFI = 'RFI',
}

export enum UserReportStatus {
  NEW = 'NEW',
  Open = 'OPEN',
  Solved = 'SOLVED',
}

export type UserReport = Readonly<{
  id: UUID
  type: UserReportType
  reference_id: string
  status: UserReportStatus
  created_at: TimestampISO
  description: string
}>

export type EnhancedTableProps<StylesType> = {
  classes: StylesType
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserReport) => void
  order?: Order
  orderBy?: string
  rowCount: number
}
