import React from 'react'
import styled from 'styled-components'

import { Link } from '@reach/router'
import { ClientRoutePaths } from 'utils/consts'
import LogoImage from './revolut_logo.png'

export const TEXT_LOGO = 'Revolut'

const LogoStyled = styled.img`
  margin: 0;
  max-width: 13rem;
`

type LogoProps = {
  maxWidth?: string
}

export const Logo = (props: LogoProps): JSX.Element => (
  <Link to={ClientRoutePaths.Index}>
    <LogoStyled src={LogoImage} alt={TEXT_LOGO} style={props} />
  </Link>
)
