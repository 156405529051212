import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { Accordion, AccordionDetails, Divider, Grid } from '@material-ui/core'

import { PartnerItem } from 'utils/types'

import { Preloader } from 'components'
import { EmailsList } from './EmailsList'
import { PartnerItemEditor } from './PartnerItemEditor'
import { PartnerCardFormHeader } from './PartnerCardFormHeader'
import { PartnerCardEditContext } from './PartnerCardEditContext'
import { PartnerCardFormActions } from './PartnerCardFormActions'
import { usePartnerCardFormSubmit } from './usePartnerCardFormSubmit'

export const TEXT_PartnerCard = {
  expandCardButtonTitle: 'Expand partner card',
}

export const PartnerCard = (props: PartnerItem): JSX.Element => {
  const submitHandler = usePartnerCardFormSubmit()

  const { whitelisted_emails } = props

  return (
    <PartnerCardEditContext defaultValues={props}>
      <Form<PartnerItem>
        onSubmit={submitHandler}
        mutators={{ ...arrayMutators }}
        initialValues={props}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Accordion>
              <PartnerCardFormHeader />
              <Divider />
              <AccordionDetails>
                {submitting ? (
                  <Grid item xs={12}>
                    <Preloader wrapper={{ height: '11rem' }} />
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    <PartnerItemEditor />
                    {whitelisted_emails.length ? <EmailsList /> : null}
                  </Grid>
                )}
              </AccordionDetails>
              <PartnerCardFormActions />
            </Accordion>
          </form>
        )}
      </Form>
    </PartnerCardEditContext>
  )
}
