import React from 'react'
import { Box, Grid } from '@material-ui/core'

import { SubmitButton, SubmitButtonProps } from './SubmitButton'

export const SubmitFormButton = (props: SubmitButtonProps): JSX.Element => (
  <Grid item xs={12} sm={6}>
    <Box marginTop={7}>
      <SubmitButton fullWidth color="secondary" {...props} />
    </Box>
  </Grid>
)
