import React, { FunctionComponent } from 'react'
import { InputLabel, Grid, Box, GridProps } from '@material-ui/core'
import styled from 'styled-components'

import { CardNotPresentReport, isIEBrowser } from 'utils'
import {
  composeValidators,
  exactValueLength,
  mustBeNumber,
  requiredField,
} from 'utils/formValidators'
import { TextInput } from 'components/forms/commonFields'

export const TEXT_CARD_NUMBER_FIELD = {
  label: 'Compromised Card Number',
  separator: '******',
  firstSix: {
    label: 'First 6',
    placeholder: '123456',
  },
  lastFour: {
    label: 'Last 4',
    placeholder: '4321',
  },
}

// had to use maxwidth cause later MUI-kit will pass this prop to DOM node
const StyledGrid = styled(Grid)<GridProps & { maxwidth?: number }>`
  max-width: ${({ maxwidth }) => `${maxwidth}`}rem !important;
`

const isIE = isIEBrowser()

export const CardNumberField: FunctionComponent = (): JSX.Element => (
  <>
    <InputLabel>{TEXT_CARD_NUMBER_FIELD.label}</InputLabel>
    <Box my={3}>
      <Grid container spacing={1} alignItems="center">
        <StyledGrid item xs={3} sm={2} {...(!isIE && { maxwidth: 6 })}>
          <TextInput<CardNotPresentReport>
            {...TEXT_CARD_NUMBER_FIELD.firstSix}
            name="first_six"
            validate={composeValidators(requiredField, mustBeNumber, exactValueLength(6))}
            {...(isIE && {
              inputProps: {
                style: {
                  width: '6rem',
                },
              },
            })}
          />
        </StyledGrid>

        <Grid item>{TEXT_CARD_NUMBER_FIELD.separator}</Grid>

        <Grid item xs={3} md={2}>
          <Grid item xs={9} md={7}>
            <TextInput<CardNotPresentReport>
              {...TEXT_CARD_NUMBER_FIELD.lastFour}
              name="last_four"
              validate={composeValidators(
                requiredField,
                mustBeNumber,
                exactValueLength(4),
              )}
              {...(isIE && {
                inputProps: {
                  style: {
                    width: '4rem',
                  },
                },
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </>
)
