import React from 'react'
import { AffirmationRadioItem, AffirmationsListType } from 'components/forms/commonFields'

export const uploadDocumentStepAffirmations: AffirmationsListType = [
  {
    id: 'is_fraudulent_activity',
    value: false,
    text: 'Is the reason for the request for information related to any suspected fraudulent activities?',
    render: props => <AffirmationRadioItem {...props} />,
  },
  {
    id: 'is_money_laundering',
    value: false,
    text: 'Is the reason for the request for information related to any money laundry concerns?',
    render: props => <AffirmationRadioItem {...props} />,
  },
]
