export const TEXT_IDENTIFICATION_STEP = {
  validationFailedMessage: 'validation failed',
  errorText: {
    INTERNAL_ERROR:
      'Unfortunately, we were unable to process the request at the moment, please try again later.',
    USER_NOT_VALID:
      'Unfortunately, we were unable to find a valid account for the entered data. If you have entered the wrong data, please try again.',
    USER_NOT_FOUND:
      'Unfortunately, we were unable to find a matching account for the entered data. If you have entered the wrong data, please try again.',
    paragraph:
      'Otherwise, please contact <b>courtorders@revolut.com</b> with the necessary information and our dedicated teams will be able to support you.',
  },
  backToPortalButtonText: 'Back to portal',
}
