import React, { FunctionComponent, useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { Box, Grid } from '@material-ui/core'

import { BackToPortalButton, SubmitFormButton } from 'components/buttons'
import { AuthorisationCodeField, Transactions } from 'components/forms/commonFields'

import { useCardNotPresentFormSubmit } from './useCardNotPresentFormSubmit'
import { CardNotPresentFormConfirmation } from './CardNotPresentFormConfirmation'
import { CardNumberField } from './fields/CardNumberField'
import { DescriptionField } from './fields/DescriptionField'

export const CardNotPresentForm: FunctionComponent = () => {
  const [referenceNumber, setReferenceNumber] = useState<string>()
  const submitForm = useCardNotPresentFormSubmit()

  return (
    <Form
      mutators={{ ...arrayMutators }}
      onSubmit={async formValues => {
        const refNumber = await submitForm(formValues)

        if (refNumber) {
          setReferenceNumber(refNumber)
        }
      }}
    >
      {({ handleSubmit, invalid, submitting, submitSucceeded }) => (
        <>
          {submitSucceeded && referenceNumber ? (
            <>
              <CardNotPresentFormConfirmation referenceNumber={referenceNumber} />
              <Box marginTop={7}>
                <Grid item xs="auto" sm={5} md={3}>
                  <BackToPortalButton />
                </Grid>
              </Box>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <CardNumberField />
              <Transactions>
                <AuthorisationCodeField />
              </Transactions>
              <DescriptionField />
              <SubmitFormButton disabled={submitting || invalid} />
            </form>
          )}
        </>
      )}
    </Form>
  )
}
