export type UserAccessRights = {
  hasAccessToFraud: boolean
  hasAccessToRfi: boolean
}

export const getUserAccess = (permissions: string[]): UserAccessRights => {
  return {
    hasAccessToFraud: permissions.includes('hades.fraud_requester'),
    hasAccessToRfi: permissions.includes('hades.rfi_requester'),
  }
}
