import React from 'react'

import { AccountType, Nullable } from 'utils'
import {
  AffirmationsListType,
  RequestForInformationDocumentsAffirmationsList,
} from 'components/forms/commonFields'

import { UploadDocumentStep } from './UploadDocumentStep'
import { SelectDocumentsStep } from './SelectDocumentsStep'
import { IdentificationStep } from './IdentificationStep/IdentificationStep'
import { RequestForInFormationSubmitStep } from './RequestForInFormationSubmitStep'

export type RequestForInformationFormStepsValues = {
  rfi_id?: string
  document?: { file: File; value: string }
  select_documents_affirmations: RequestForInformationDocumentsAffirmationsList
  upload_document_affirmations: AffirmationsListType
} & (
  | {
      account_type: AccountType.SCAN
      sort_code: number
      account_number: number
    }
  | {
      account_type: AccountType.IBAN
      iban: string
    }
)

type RequestForInformationFormStep = {
  title: string
  render: () => Nullable<JSX.Element>
}

export const requestForInformationFormSteps: Array<RequestForInformationFormStep> = [
  {
    title: 'identification',
    render: () => <IdentificationStep />,
  },
  {
    title: 'Upload Document',
    render: () => <UploadDocumentStep />,
  },
  {
    title: 'Select documents',
    render: () => <SelectDocumentsStep />,
  },
  {
    title: 'Submit Request',
    render: () => <RequestForInFormationSubmitStep />,
  },
]
