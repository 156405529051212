import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

export const TEXT_SUBMIT_BUTTON = 'Submit'

export type SubmitButtonProps = ButtonProps & {
  loading?: boolean
  text?: string
}

export const SubmitButton = ({
  text = TEXT_SUBMIT_BUTTON,
  loading,
  ...props
}: SubmitButtonProps): JSX.Element => (
  <Button type="submit" color="primary" variant="contained" title={text} {...props}>
    {loading ? <CircularProgress size={24} /> : text}
  </Button>
)
