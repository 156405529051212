import React from 'react'
import Autosuggest from 'react-autosuggest'
import { TextField, Paper, Typography } from '@material-ui/core'
import currencies_json from './currencies.json'

const currencies = Object.values(currencies_json)

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value: string) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  return inputLength === 0
    ? []
    : currencies.filter(
        currency => currency.code.toLowerCase().slice(0, inputLength) === inputValue,
      )
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
// @ts-expect-error
const getSuggestionValue = suggestion => suggestion.code

// Use your imagination to render suggestions.
// @ts-expect-error
const renderSuggestion = suggestion => (
  <span>
    <Typography gutterBottom>{suggestion.code}</Typography>
  </span>
)

class CurrencySelector extends React.Component {
  // @ts-expect-error
  constructor(props) {
    super(props)

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      // @ts-expect-error
      value: this.props.currency,
      suggestions: [],
    }
  }

  // @ts-expect-error
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue.trim().toUpperCase().substring(0, 3),
    })
  }

  componentDidUpdate() {
    // @ts-expect-error
    if (currencies_json[this.state.value]) {
      // @ts-expect-error
      this.props.onCurrencyChange(this.state.value)
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  // @ts-expect-error
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    // @ts-expect-error
    const { value, suggestions } = this.state
    // @ts-expect-error
    const { transaction_num } = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'GBP',
      id: `transaction_currency_${transaction_num}`,
      value,
      onChange: this.onChange,
    }

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion
        renderInputComponent={textFieldProps => (
          // @ts-expect-error
          <TextField
            {...textFieldProps}
            style={{ width: '100%' }}
            variant="outlined"
            label="Currency"
          />
        )}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <Paper
              {...containerProps}
              style={{ padding: children ? '0.5rem' : 0 }}
              elevation={2}
            >
              {children}
            </Paper>
          )
        }}
      />
    )
  }
}

export default CurrencySelector
