import React, { useEffect } from 'react'
import { useFieldArray } from 'react-final-form-arrays'

type AffirmationsListProps<DataType> = {
  name: keyof DataType | string
  initialValues?: Array<DataType>
}

export const AffirmationsList = <DataType extends { render: Function }>({
  name,
  initialValues,
}: AffirmationsListProps<DataType>) => {
  const { fields } = useFieldArray<DataType>(name as string)

  // workaround https://github.com/final-form/react-final-form-arrays/issues/111
  useEffect(() => {
    initialValues?.forEach(value => fields.push(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {fields.map((fieldName, fieldIndex) => {
        const { render, ...props } = fields.value[fieldIndex]

        return <div key={fieldName}>{render({ name: fieldName, ...props })}</div>
      })}
    </>
  )
}
