import React from 'react'
import { FormControl, Grid } from '@material-ui/core'
import { TextInput } from './TextInput'
import { useTransactionContext } from './Transactions/TransactionContext'

export const TEXT_DESCRIPTION_FIELD = {
  label: 'Description',
  placeholder: 'RejectReason (optional)',
}

export const DescriptionField = () => {
  const { field } = useTransactionContext()

  return (
    <Grid item xs={12} sm={8} md={6}>
      <FormControl fullWidth margin="normal">
        <TextInput name={`${field}.description`} {...TEXT_DESCRIPTION_FIELD} />
      </FormControl>
    </Grid>
  )
}
