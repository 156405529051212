import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const HtmlContainer = styled.div``

type InnerHTMLProps = {
  html: string
} & React.ComponentProps<typeof HtmlContainer>

export const InnerHTML: FunctionComponent<InnerHTMLProps> = ({ html, ...props }) => (
  // eslint-disable-next-line react/no-danger
  <HtmlContainer {...props} dangerouslySetInnerHTML={{ __html: html }} />
)
