import { createContext, ReactNode, useContext } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'

import { Transaction } from 'utils/types'

type TransactionContextType<DataType = {}> = {
  additionalFields: ReactNode
  fields: FieldArrayRenderProps<Transaction & DataType, HTMLDivElement>['fields']
  field: string
  index: number
}
export const TransactionContext = createContext(null as unknown as TransactionContextType)
export const useTransactionContext = () => useContext(TransactionContext)
