import React from 'react'
import { useField } from 'react-final-form'

import { TextField, Tooltip } from '@material-ui/core'
import { TextInputProps } from 'utils'

export const TextInput = <DataType extends {}>({
  name,
  initialValue,
  validate,
  format,
  helperText,
  autoComplete = 'off',
  defaultValue,
  ...textFieldProps
}: TextInputProps<DataType>) => {
  const {
    input,
    meta: { active, error, invalid, touched },
  } = useField(name as string, { initialValue, validate, format, defaultValue })

  return (
    <Tooltip open={active && Boolean(error)} title={error || ''} placement="top-end">
      <TextField
        {...input}
        value={input.value ?? ''}
        autoComplete={autoComplete}
        helperText={helperText}
        error={(touched || active) && invalid}
        variant="outlined"
        {...textFieldProps}
      />
    </Tooltip>
  )
}
