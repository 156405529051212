import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { AffirmationsListType } from 'components/forms/commonFields'
import { composeValidators, mustBeTrue, requiredField } from 'utils/formValidators'

export const useAllSelectedValidation = (name: string) => {
  const field = useField<AffirmationsListType>(name)
  const AllSelected = field.input.value.every(({ value }) => value === true)

  const stepValidatorField = useField(`${name}Valid`, {
    initialValue: String(AllSelected),
    validate: composeValidators(requiredField, mustBeTrue),
  })

  useEffect(() => {
    stepValidatorField.input.onChange(AllSelected)

    // todo: should possibly include all the dependencies in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllSelected])
}
