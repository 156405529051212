import React from 'react'
import { Button } from '@material-ui/core'

import { useTransactionContext } from '../forms/commonFields/Transactions/TransactionContext'

const TEXT_REMOVE_TRANSACTION = 'Remove Transaction'

export const RemoveTransactionButton = () => {
  const { fields, index } = useTransactionContext()

  return (
    <Button size="small" color="secondary" onClick={() => fields.remove(index)}>
      {TEXT_REMOVE_TRANSACTION}
    </Button>
  )
}
