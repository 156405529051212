import { useQuery } from 'react-query'

import { PageInfo } from 'utils'
import { APIRoutePaths } from 'utils/consts'
import { cerberusRequest } from 'services/Cerberus'
import { UserReport } from './types'

export type GetReportsQueryReturnType = {
  reports: ReadonlyArray<UserReport>
  page_info: PageInfo
}

export type getUserReportsQueryVariables = {
  limit: number
  skip: number
}

const getUserReportsQuery = (params: getUserReportsQueryVariables) =>
  cerberusRequest<getUserReportsQueryVariables, GetReportsQueryReturnType>({
    url: APIRoutePaths.CurrentUserReports,
    method: 'GET',
    params,
  })

export const useGetUserReports = ({ skip, limit }: getUserReportsQueryVariables) =>
  useQuery([APIRoutePaths.CurrentUserReports, skip, limit], () =>
    getUserReportsQuery({ skip, limit }),
  )
