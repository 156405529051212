import React from 'react'
import { FormControl, Grid } from '@material-ui/core'
import { TextInput } from 'components/forms/commonFields/TextInput'
import { CardNotPresentFormTransactionKeys } from 'utils'
import { useTransactionContext } from './Transactions/TransactionContext'

export const TEXT_AUTHORIZATION_CODE = 'Authorization Code'

const fieldName: CardNotPresentFormTransactionKeys = 'authorisation_code'

export const AuthorisationCodeField = () => {
  const { field } = useTransactionContext()

  return (
    <Grid item sm={8}>
      <FormControl fullWidth>
        <TextInput
          name={`${field}.${fieldName}`}
          label={TEXT_AUTHORIZATION_CODE}
          placeholder={TEXT_AUTHORIZATION_CODE}
        />
      </FormControl>
    </Grid>
  )
}
