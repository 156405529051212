/* istanbul ignore file */ // POS will be removed after approval
import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { AffirmationsListType } from 'components/forms/commonFields'
import { composeValidators, mustBeTrue, requiredField } from 'utils/formValidators'

/**
 * @description watches checkbox list & toggle form valid if atLeast one item selected
 * @param {string} name
 */
export const useAtLeastOneSelectedValidation = (name: string) => {
  const {
    input: { value },
  } = useField<AffirmationsListType>(name)

  const atLeastOneSelected = value.length ? value.some(({ value: val }) => val) : false

  const stepValidatorField = useField(`${name}Valid`, {
    initialValue: String(atLeastOneSelected),
    validate: composeValidators(requiredField, mustBeTrue),
  })

  useEffect(() => {
    stepValidatorField.input.onChange(atLeastOneSelected)

    // todo: should possibly include all the dependencies in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atLeastOneSelected])
}
