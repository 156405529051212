import React, { FunctionComponent } from 'react'
import { DeleteOutlineRounded } from '@material-ui/icons'

import { ConfirmationButton, ConfirmationButtonProps } from 'components/buttons'

const TEXT_DeleteButton = {
  title: 'delete',
}

export const DeleteButton: FunctionComponent<ConfirmationButtonProps> = props => (
  <ConfirmationButton title={TEXT_DeleteButton.title} {...props}>
    <DeleteOutlineRounded />
  </ConfirmationButton>
)
