import { APIRoutePaths } from 'utils/consts'
import { getFullApiRoutePath } from 'utils/url'
import { getUserReportsQueryVariables } from 'pages/Reports/ReportsTable/useGetUserReports'
import { MockServerEndpointHandler } from './types'

export const userReportsHandlers: MockServerEndpointHandler = ({ get }) => {
  get(
    getFullApiRoutePath(APIRoutePaths.CurrentUserReports),
    (schema, { queryParams }) => {
      const { models: reportsList, length } = schema.all('report')

      const { limit, skip } = queryParams as unknown as getUserReportsQueryVariables

      const limitNumber = parseInt(limit as unknown as string)
      const totalPages = Math.round(length / limitNumber)

      const page = skip / limitNumber + 1
      const hasNextPage = page < totalPages
      const hasPreviousPage = page > 0 && page <= totalPages

      return {
        reports: reportsList.slice(skip).slice(0, limitNumber),
        page_info: {
          has_next_page: hasNextPage,
          has_previous_page: hasPreviousPage,
          page,
          per_page: limitNumber,
          total_pages: totalPages,
          total_items: length,
        },
      }
    },
  )
}
