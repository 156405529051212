import { Transaction, TransactionsList } from 'utils/types'

export const DEFAULT_NOTIFICATION_TIME = 3000

export const API_HOST = `/api`

export const defaultTransaction: Partial<Transaction> = {
  currency: 'GBP',
}
export const initialTransactions = [defaultTransaction] as TransactionsList

export enum AdminClientRoutePaths {
  Root = '/admin',
  Automations = '/automations',
  ManagePartners = '/manage-partners',
}

export enum AdminAPIRoutePaths {
  PartnersFraud = '/v1/three-headed/partners-fraud',
  CheckAdminAccess = '/v1/three-headed/check-access',
  PartnersRequestForInformation = '/v1/three-headed/partners-rfi',
  RequestForInformationPendingUserReject = '/v1/three-headed/partners-rfi/reject',
  RequestForInformationPendingUserApprove = '/v1/three-headed/partners-rfi/approve',
  RequestForInformationPendingUsers = '/v1/three-headed/partners-rfi/pending-users',
}

export enum ClientRoutePaths {
  Reports = '/reports',
  RequestForInformation = '/request-for-information',
  RequestForInformationDownloads = '/request-for-information/:rfiId/download',
  RequestForInformationReview = '/request-for-information/:rfiId/review',
  RequestForInformationValidationError = '/request-for-information/:rfiId/validationError',
  AuthorisedPushPaymentForm = '/authorised-push-payment',
  CardNotPresentForm = '/card-not-present',
  Index = '/index',
  Login = '/login',
  ForBidden = '/403',
  NotFound = '/404',
  Root = '/',
}

export enum RoutePathsDynamicKeys {
  RequestForInformationId = ':rfiId',
  RequestForInformationDocumentType = ':docType',
}

export enum APIRoutePaths {
  Login = '/v2/hades/auth/login/token',
  SignIn = '/v2/hades/auth/signin',
  LogOut = '/v2/hades/auth/logout',
  WhoAmI = '/v2/hades/auth/whoami',
  CreateCardNotPresentRequest = '/v1/hades/create/cnp',
  SubmitAuthorisedPushPaymentRequest = '/v1/hades/authorised-push-payment',
  CurrentUserReports = '/v1/hades/current-user/reports',
  RequestForInformationAccountIdentificationValidation = '/v2/hades/rfi/identify-account',
  RequestForInformationUploadReferenceDocument = '/v2/hades/rfi/:rfiId/upload-reference',
  RequestForInformationDownloadDocumentsList = '/v2/hades/rfi/:rfiId/documents',
  RequestForInformationSampleDocs = '/v2/hades/rfi/documents/available',
  RequestForInformationSampleDoc = '/v2/hades/rfi/documents/samples/:docType',
  RequestForInformationRequestDocs = '/v2/hades/rfi/:rfiId/request-documents',
  RequestForInformationApproveRequest = '/v2/hades/rfi/:rfiId/approve',
  RequestForInformationRejectRequest = '/v2/hades/rfi/:rfiId/reject',
  RequestForInformationReview = '/v2/hades/rfi/:rfiId/review',
  RequestForInformationReviewReferenceDocument = '/v2/hades/rfi/:rfiId/reference-document/file',
}

export enum CommonErrorMessages {
  UserNotLoggedIn = 'user not logged in',
  AuthTokenIsMissing = 'auth token is missing',
  AuthenticationFailed = 'authentication failed',
  RequestForInformationReferenceIdMissing = 'required rfi_id is missing',
  RequestForInformationReferenceDocumentMissing = 'reference document is missing',
  HasNoAccess = 'user has no access to requested entity',
  RequestAlreadySubmitted = 'request already submitted',
}

export const ACCOUNT_NUMBER_LENGTH = 12
