import React from 'react'
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core'

import { InnerHTML } from 'components'
import { useField } from 'react-final-form'

type AffirmationCheckBoxItemProps = {
  text: string
  name: string
  afterTitle?: JSX.Element
  children?: () => JSX.Element
}

export const AffirmationCheckBoxItem = ({
  text,
  name,
  children,
  afterTitle,
}: AffirmationCheckBoxItemProps): JSX.Element => {
  const {
    input: { name: fieldName, value, onChange },
  } = useField(name, { type: 'CheckBox' })

  const label = afterTitle ? (
    <Grid container spacing={2}>
      <Grid item>
        <InnerHTML html={text} />
      </Grid>
      <Grid item>{afterTitle}</Grid>
    </Grid>
  ) : (
    <InnerHTML html={text} />
  )

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            inputProps={{ id: name, title: name }}
            name={fieldName}
            value={value?.value ?? false}
            onChange={({ target: { checked } }) =>
              onChange({ target: { value: { ...value, value: checked } } })
            }
          />
        }
      />
      {children && value.value && <Box my={2}>{children()}</Box>}
    </>
  )
}
