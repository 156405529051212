import { FactoryDefinition } from 'miragejs/-types'

import { mockServerModels } from '../models'
import { createPartnerMockFactory } from './createPartnerMockFactory'
import { createMockUserReportFactory } from './createMockUserReportFactory'
import { createPendingPartnerMockFactory } from './createPendingPartnerMockFactory'
import { createMockRequestForInformationSampleDocumentFactory } from './createMockRequestForInformationSampleDocumentFactory'

export * from './createPartnerMockFactory'
export * from './createMockUserReportFactory'
export * from './createMockRequestForInformationSampleDocumentFactory'

export const mockServerModelFactories: Record<
  keyof typeof mockServerModels,
  FactoryDefinition
> = {
  report: createMockUserReportFactory(),
  partner: createPartnerMockFactory(),
  pendingPartner: createPendingPartnerMockFactory(),
  rfiDocument: createMockRequestForInformationSampleDocumentFactory(),
}
