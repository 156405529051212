import React, { FunctionComponent } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import { FormCard } from './FormCard'
import { TEXT_FORMS_LIST, useFormCardsList } from './useFormCardsList'

export const FormList: FunctionComponent = () => {
  const formCards = useFormCardsList()
  const hasFormCards = Boolean(formCards.length)

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          {hasFormCards ? TEXT_FORMS_LIST.heading : TEXT_FORMS_LIST.requestAccessToForms}
        </Typography>
      </Grid>
      {hasFormCards && (
        <Box mt={4}>
          <Grid container spacing={2} alignItems="stretch">
            {formCards.map(props => (
              <Grid key={props.title} item md={6} xs={12}>
                <FormCard {...props} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  )
}
