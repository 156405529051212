import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Box, Button, Link, Typography } from '@material-ui/core'

import { Layout } from 'components/Layout'
import { ClientRoutePaths } from 'utils/consts'
import { useNavigateWithSearchParams } from 'utils'

export const TEXT_HOME_PAGE = {
  heading: 'External Enquiries Portal',
  loginButtonText: 'log in to continue',
  requestAccessEmail: 'ee.portal@revolut.com',
  subHeading: '*If you don’t have access, please request access by emailing',
}

export const Home: FunctionComponent<RouteComponentProps> = () => {
  const navigateWithSearchParams = useNavigateWithSearchParams()

  return (
    <Layout>
      <Box mb={6}>
        <Typography variant="h4">{TEXT_HOME_PAGE.heading}</Typography>
      </Box>
      <Button
        onClick={() => navigateWithSearchParams(ClientRoutePaths.Login)}
        variant="contained"
        color="primary"
        size="large"
      >
        {TEXT_HOME_PAGE.loginButtonText}
      </Button>
      <Box mt={4}>
        <Typography variant="body2">
          {TEXT_HOME_PAGE.subHeading} &nbsp;
          <Link href={`mailto:${TEXT_HOME_PAGE.requestAccessEmail}`}>
            {TEXT_HOME_PAGE.requestAccessEmail}
          </Link>
        </Typography>
      </Box>
    </Layout>
  )
}
