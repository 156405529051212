import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Grid, Paper } from '@material-ui/core'

import { Layout } from 'components'
import { ManagePartnersTable } from '../ManagePartnersTable'

export const ManagePartners: FunctionComponent<RouteComponentProps> = (): JSX.Element => (
  <Layout>
    <Grid item>
      <Paper variant="outlined">
        <ManagePartnersTable />
      </Paper>
    </Grid>
  </Layout>
)
