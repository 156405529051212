import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import { AddPartner } from './components/AddPartnerEmail'
import { PartnersList } from './components/PartnersList'
import { ManagePartnersContext } from './ManagePartnersContext'

export const TEXT_MANAGE_PARTNERS_TABLE = {
  heading: 'Manage partners',
}

export const ManagePartnersTable = (): JSX.Element => (
  <ManagePartnersContext>
    <Box m={3} mb={1}>
      <Box mb={3}>
        <Typography variant="h6">{TEXT_MANAGE_PARTNERS_TABLE.heading}</Typography>
      </Box>
      <Grid container>
        <AddPartner />
        <PartnersList />
      </Grid>
    </Box>
  </ManagePartnersContext>
)
