/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'

import { Box, Button } from '@material-ui/core'
import { Add as IconAdd } from '@material-ui/icons'

type AddButtonProps = {
  onClick: VoidFunction
  text: string
}

export const AddButton = ({ text, onClick }: AddButtonProps): JSX.Element => (
  <Box my={5}>
    <Button variant="outlined" color="secondary" onClick={onClick}>
      <IconAdd />
      {text}
    </Button>
  </Box>
)
