import React, { PropsWithChildren } from 'react'
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core'

export type TooltipButtonProps = IconButtonProps

export const TooltipButton = ({
  color,
  title = 'Button',
  children,
  ...buttonProps
}: PropsWithChildren<TooltipButtonProps>) => (
  <IconButton title={title} {...buttonProps}>
    <Tooltip title={title}>
      <>{children}</>
    </Tooltip>
  </IconButton>
)
