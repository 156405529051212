export const stableSort = <T>(
  array: ReadonlyArray<T>,
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

export const descendingComparator = <DataType>(
  a: DataType,
  b: DataType,
  orderBy: keyof DataType,
) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export type Order = 'asc' | 'desc'

type GetComparatorReturnType<DataType> = (a: DataType, b: DataType) => number

export const getComparator = <DataType>(
  order?: Order,
  orderBy?: keyof DataType,
): GetComparatorReturnType<DataType> => {
  if (!order || !orderBy) return () => 0

  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
