import faker from 'faker'
import { Response } from 'miragejs'

import { getFullApiRoutePath } from 'utils/url'
import { AdminAPIRoutePaths, APIRoutePaths } from 'utils/consts'

import { ValidateUrlTokenQueryResponse } from 'services/CerberusAuth/queries'
import { MockServerEndpointHandler } from './types'

export const authenticationHandlers: MockServerEndpointHandler = ({ get, post }) => {
  get(getFullApiRoutePath(AdminAPIRoutePaths.CheckAdminAccess), () => new Response(200))
  post(getFullApiRoutePath(APIRoutePaths.SignIn), () => new Response(204))
  post(getFullApiRoutePath(APIRoutePaths.LogOut), () => new Response(204))

  post(getFullApiRoutePath(APIRoutePaths.Login), (_, request) => {
    const { token } = JSON.parse(request.requestBody)

    if (!token) {
      return new Response(422)
    }

    return new Response(200)
  })

  get(getFullApiRoutePath(APIRoutePaths.WhoAmI), () => {
    const responseBody: ValidateUrlTokenQueryResponse = {
      id: faker.random.uuid(),
      permissions: ['hades.fraud_requester', 'hades.rfi_requester'],
    }

    return new Response(200, {}, responseBody)
  })
}
