import { useQuery } from 'react-query'
import { useField, useForm, useFormState } from 'react-final-form'
import { flow, forEach, get } from 'lodash/fp'

import { useNotifications } from 'utils'

import { parseApiCustomError } from 'utils/parseApiCustomError'
import { validateIdentificationStep } from '../stepsValidationQueries'
import { RequestForInformationFormStepsValues } from '../requestForInformationFormSteps'

export const useIdentificationStepValidationQuery = () => {
  const { submit } = useForm()
  const { values } = useFormState<RequestForInformationFormStepsValues>()
  const {
    input: { onChange: setReferenceId },
  } = useField('rfi_id')
  const { toastError } = useNotifications()

  return useQuery(
    'validateIdentificationStep',
    () => {
      let payload

      if (values.account_type === 'IBAN') {
        payload = { iban: values.iban }
      }

      if (values.account_type === 'SCAN') {
        payload = {
          account: {
            sort_code: values.sort_code,
            account_number: values.account_number,
          },
        }
      }

      return validateIdentificationStep({
        account_type: values.account_type,
        ...payload,
      })
    },
    {
      enabled: false,
      onSuccess: ({ data: { rfi_id, status } }) => {
        if (status !== 'USER_VALID') return

        setReferenceId({ target: { value: rfi_id } })
        submit()
      },
      onError: flow(get('response.data'), parseApiCustomError, forEach(toastError)),
    },
  )
}
