import { cerberusRequest } from 'services/Cerberus'
import { AuthorisedPushPaymentFormFieldsKeys, useNotifications } from 'utils'

import {
  AuthorisedPushPaymentFormSubmitPayload,
  AuthorisedPushPaymentFormValues,
  CerberusSubmitReturn,
} from 'utils/types'
import { omit } from 'lodash/fp'
import { formatISO } from 'date-fns/fp'
import { APIRoutePaths } from 'utils/consts'

import { TEXT_AuthorisedPushPaymentForm } from './AuthorisedPushPaymentForm'

export const useAuthorisedPushPaymentFormSubmit = () => {
  const { toastError } = useNotifications()

  return async (formValues: Omit<AuthorisedPushPaymentFormValues, 'timestamp'>) => {
    try {
      const { transactions, ...restFormValues } = omit(
        AuthorisedPushPaymentFormFieldsKeys.Affirmations,
        formValues,
      )

      const payload = omit(
        `${AuthorisedPushPaymentFormFieldsKeys.Affirmations}Valid`,
        restFormValues,
      ) as Omit<AuthorisedPushPaymentFormSubmitPayload, 'transactions'>

      const transactionsWithTimestamp = transactions.map(transaction => ({
        ...transaction,
        timestamp: formatISO(new Date(transaction.timestamp).getTime()),
      }))

      const {
        data: { reference_number: referenceNumber },
      } = await cerberusRequest<
        AuthorisedPushPaymentFormSubmitPayload,
        CerberusSubmitReturn
      >({
        url: APIRoutePaths.SubmitAuthorisedPushPaymentRequest,
        data: {
          ...payload,
          transactions: transactionsWithTimestamp,
        },
      })

      return referenceNumber
    } catch ({ message }) {
      toastError(`${TEXT_AuthorisedPushPaymentForm.messages.error} ${message}`)

      return null
    }
  }
}
