import React, { FunctionComponent } from 'react'
import { AccordionActions } from '@material-ui/core'
import { useFormState } from 'react-final-form'

import { PartnerItem } from 'utils'
import { PartnerCardEditActions } from './PartnerCardEditActions'
import { EditButton } from '../buttons'
import { useEditContext } from './PartnerCardEditContext'

export const PartnerCardFormActions: FunctionComponent = () => {
  const {
    values: { id, domain_name },
    dirty,
  } = useFormState<PartnerItem>()

  const { isEdit, editActions } = useEditContext()

  return (
    <AccordionActions>
      {isEdit ? (
        <PartnerCardEditActions
          partnerId={id}
          isFormChanged={dirty}
          name={domain_name}
          disableEditMode={editActions.toggle}
        />
      ) : (
        <EditButton onClick={editActions.toggle} />
      )}
    </AccordionActions>
  )
}
