/* istanbul ignore file */ // POS will be removed after approval
import { AccountType, Transaction } from 'utils'

export enum RequestForInformationFormFieldsKeys {
  // eslint-disable-next-line no-shadow
  AccountType = 'account_type',
  AccountNumber = 'account_number',
  CardNumber = 'card_number',
  SortCode = 'sort_code',
  SelectDocumentsAffirmations = 'select_documents_affirmations',
  UploadDocumentAffirmations = 'upload_document_affirmations',
  Transactions = 'transactions',
  IBAN = 'iban',
}

type RequestForInformationTransactionsList = Array<RequestForInformationTransaction>

export type RequestForInformationTransaction = Transaction & {
  beneficiary: string
  description?: string
}

type RequestForInformationFormValuesFragment = {
  [RequestForInformationFormFieldsKeys.Transactions]?: RequestForInformationTransactionsList
  [RequestForInformationFormFieldsKeys.SelectDocumentsAffirmations]: Array<boolean>
}

export type RequestForInformationFormValues = RequestForInformationFormValuesFragment &
  (
    | {
        [RequestForInformationFormFieldsKeys.AccountType]: AccountType.CardNumber
        [RequestForInformationFormFieldsKeys.CardNumber]: string
      }
    | {
        [RequestForInformationFormFieldsKeys.AccountType]: AccountType.IBAN
        [RequestForInformationFormFieldsKeys.IBAN]: string
      }
    | {
        [RequestForInformationFormFieldsKeys.AccountType]: AccountType.SCAN
        [RequestForInformationFormFieldsKeys.SortCode]: string
        [RequestForInformationFormFieldsKeys.AccountNumber]: string
      }
  )
