import React from 'react'
import { Switch, SwitchProps } from '@material-ui/core'
import { useField, FieldProps } from 'react-final-form'

type SwitchInputProps<DataType extends Record<string, any>> = {
  name: keyof DataType
} & SwitchProps &
  Pick<FieldProps<boolean, any, HTMLInputElement>, 'validate' | 'initialValue' | 'format'>

export const SwitchInput = <DataType,>({
  name,
  format,
  validate,
  initialValue,
}: SwitchInputProps<DataType>): JSX.Element => {
  const { input } = useField(name, { initialValue, validate, format, type: 'checkbox' })

  return <Switch {...input} />
}
