/* istanbul ignore file */ // POS will be removed after approval
import { useQuery } from 'react-query'
import { AxiosError, AxiosPromise } from 'axios'
import { useForm, useFormState } from 'react-final-form'
import { cond, flow, forEach, get, isError } from 'lodash/fp'

import { navigate } from '@reach/router'

import { ServerError, useNotifications } from 'utils'
import { ClientRoutePaths, CommonErrorMessages } from 'utils/consts'
import { parseApiCustomError } from 'utils/parseApiCustomError'
import {
  isAxiosError,
  isStatusCustomError,
  isStatusForbidden,
  isStatusUnauthorised,
} from 'utils/axiosErrors'

import { RequestForInformationFormStepsValues } from '../requestForInformationFormSteps'
import {
  UploadDocumentStepValidationQueryVariables,
  validateUploadDocumentStep,
} from '../stepsValidationQueries'

export const useUploadDocumentStepHandler = () => {
  const { submit } = useForm()
  const { toastError } = useNotifications()
  const {
    values: { document, upload_document_affirmations, rfi_id },
  } = useFormState<RequestForInformationFormStepsValues>()

  return useQuery<AxiosPromise<void>, AxiosError<ServerError>>(
    'validateUploadDocumentStep',
    () => {
      if (!document)
        throw new Error(CommonErrorMessages.RequestForInformationReferenceDocumentMissing)
      if (!rfi_id)
        throw new Error(CommonErrorMessages.RequestForInformationReferenceIdMissing)

      const affirmations = upload_document_affirmations.reduce(
        (dict, { id, value }) => ({
          ...dict,
          [id]: value,
        }),
        {} as Pick<
          UploadDocumentStepValidationQueryVariables,
          'is_fraudulent_activity' | 'is_money_laundering'
        >,
      )

      return validateUploadDocumentStep({
        rfi_id,
        document: document.file,
        ...affirmations,
      })
    },
    {
      enabled: false,
      retry: false,
      onSuccess: submit,
      onError: cond([
        [
          isStatusCustomError,
          flow(get('response.data'), parseApiCustomError, forEach(toastError)),
        ],
        [
          isAxiosError,
          cond([
            [isStatusForbidden, () => toastError(CommonErrorMessages.HasNoAccess)],
            [
              isStatusUnauthorised,
              () => {
                toastError(CommonErrorMessages.UserNotLoggedIn)
                navigate(ClientRoutePaths.Login)
              },
            ],
            [isError, flow(get('message'), toastError)],
          ]),
        ],
        [isError, flow(get('message'), toastError)],
      ]),
    },
  )
}
