import faker from 'faker'
import { Factory } from 'miragejs'

import { PendingPartner, UserStatus } from 'pages/admin/ManagePartnersTable'
import { FactoryMap } from './types'

export const createPendingPartnerMockFactory = () =>
  Factory.extend<FactoryMap<PendingPartner>>({
    id: () => faker.random.uuid(),
    status: () => UserStatus.Created,
    partner_email: () => faker.internet.email(),
    created_at: () => new Date().toDateString(),
    updated_at: () => new Date().toDateString(),
    comment: () => faker.lorem.paragraph(faker.random.number(10)),
  })
