import React from 'react'
import { ReportsTable } from './ReportsTable/Table'

export const TEXT_REPORTS = {
  reportsTableTitle: 'My reports',
}

export const Reports = (): JSX.Element => (
  <>
    <ReportsTable title={TEXT_REPORTS.reportsTableTitle} />
  </>
)
