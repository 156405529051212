import { useNumber } from 'react-hanger/array'
import { requestForInformationFormSteps } from './requestForInformationFormSteps'

export const useRequestForInformationFormSteps = () => {
  const stepsCount = requestForInformationFormSteps.length - 1
  const [stepIndex, stepperActions] = useNumber(0, {
    upperLimit: stepsCount,
    lowerLimit: 0,
  })

  const { render: stepRender } = requestForInformationFormSteps[stepIndex]

  return { stepRender, stepIndex, navigateToNextStep: () => stepperActions.increase() }
}
