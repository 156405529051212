import React, { FunctionComponent } from 'react'
import {
  Badge,
  BadgeProps,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  TypographyProps,
} from '@material-ui/core'
import styled from 'styled-components'

const StyledCardBadge = styled(Badge)<BadgeProps>`
  right: 1rem;

  // for IE 11
  > span {
    min-width: 2.5rem;
  }
`
const StyledCardTitle = styled(Typography)<TypographyProps>`
  font-size: 1.37rem !important;
`

export type FormCardProps = {
  title: string
  bodyText: string
  action: {
    title?: string
    handler: VoidFunction
  }
  badge?: {
    badgeContent: string
  }
}

const TEXT_FormCard = {
  defaultActionTitle: 'Go to form',
}

export const FormCard: FunctionComponent<FormCardProps> = ({
  title,
  bodyText,
  action,
  badge,
}) => (
  <Card>
    <CardHeader
      title={<StyledCardTitle variant="h6">{title}</StyledCardTitle>}
      {...(badge && { action: <StyledCardBadge {...badge} color="secondary" /> })}
    />
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        {bodyText}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" fullWidth onClick={action.handler}>
        {action.title || TEXT_FormCard.defaultActionTitle}
      </Button>
    </CardActions>
  </Card>
)
