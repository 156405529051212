import { FORM_ERROR } from 'final-form'
import { captureException } from '@sentry/react'

import { LoginFormValues, useNotifications } from 'utils'
import { isStatusUnauthorised } from 'utils/axiosErrors'
import { redirectToSignIn } from 'services/IDaveAuth'
import { requestTokenMutation } from 'services/CerberusAuth/mutations'

export const TEXT_SUBMIT_MESSAGES = {
  error: 'Login Failed try later or contact Revolut admin',
}

export const useSubmitLogin = () => {
  const { toastError } = useNotifications()

  return async (formValues: LoginFormValues, form: any) => {
    try {
      await requestTokenMutation(formValues.email)

      return {}
    } catch (err) {
      if (isStatusUnauthorised(err)) {
        await redirectToSignIn()

        // we don't really care about the message here, just preventing form from going to the success state
        return { [FORM_ERROR]: '' }
      }

      toastError(TEXT_SUBMIT_MESSAGES.error)

      captureException(err, {
        tags: {
          section: 'signIn: useSubmitLogin',
        },
      })

      setTimeout(() => form.restart(formValues))

      return { [FORM_ERROR]: TEXT_SUBMIT_MESSAGES.error }
    }
  }
}
