import React from 'react'
import { Grid } from '@material-ui/core'

import { requiredField } from 'utils/formValidators'

import { TextInput } from '../TextInput'

type AffirmationDateRangeItemProps<DataType> = {
  name: keyof DataType
}
export const AffirmationDateRangeItem = <DataType extends {}>({
  name,
}: AffirmationDateRangeItemProps<DataType>): JSX.Element => (
  <Grid container spacing={4}>
    <Grid item>
      <TextInput
        type="date"
        helperText="start date"
        name={`${name}.start_date`}
        validate={requiredField}
      />
    </Grid>
    <Grid item>
      <TextInput
        type="date"
        helperText="end date"
        name={`${name}.end_date`}
        validate={requiredField}
      />
    </Grid>
  </Grid>
)
