/* istanbul ignore file */ // POS will be removed after approval
import React from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { requestForInformationFormSteps } from './requestForInformationFormSteps'

type RequestForInformationFormHeaderProps = {
  stepIndex: number
}

const StepperStyled = withStyles(() => {
  return {
    root: { paddingLeft: 0, paddingRight: 0 },
  }
})(Stepper)

export const RequestForInformationFormHeader = ({
  stepIndex,
}: RequestForInformationFormHeaderProps): JSX.Element => (
  <StepperStyled activeStep={stepIndex}>
    {requestForInformationFormSteps.map(({ title }) => {
      return (
        <Step key={title}>
          <StepLabel>{title}</StepLabel>
        </Step>
      )
    })}
  </StepperStyled>
)
