import { RefObject } from 'react'
import { UseFieldConfig } from 'react-final-form'
import { TextFieldProps } from '@material-ui/core'

import currencies from 'utils/currencies.json'

export type UUID = string
export type TimestampISO = string

export type PageInfo = Readonly<{
  has_next_page: boolean
  page: number
  per_page: number
  total_pages: number
  has_previous_page: boolean
  total_items: number
}>

export type Transaction = {
  timestamp: string
  currency: keyof typeof currencies
  amount: string
}

export enum CardNotPresentFormFieldNames {
  FirstSix = 'first_six',
  LastFor = 'last_four',
  Description = 'description',
  Transactions = 'transactions',
}

export type TransactionsList = Array<Transaction>

export type CerberusCardNotPresentTransaction = Transaction & {
  timestamp: string
  auth_ref_no: string
}

export type CardNotPresentTransaction = Omit<
  CerberusCardNotPresentTransaction,
  'auth_ref_no'
> & {
  authorisation_code: string
}

export type CerberusCardNotPresentReportTransactions =
  Array<CerberusCardNotPresentTransaction>
export type CerberusCardNotPresentReport = {
  [CardNotPresentFormFieldNames.FirstSix]: string
  [CardNotPresentFormFieldNames.LastFor]: string
  description: string
  transactions: CerberusCardNotPresentReportTransactions
}

export type CardNotPresentReportTransactions = Array<CardNotPresentTransaction>

export type CardNotPresentReport = {
  [CardNotPresentFormFieldNames.FirstSix]: string
  [CardNotPresentFormFieldNames.LastFor]: string
  description: string
  transactions: CardNotPresentReportTransactions
}

export type CardNotPresentFormTransactionKeys = keyof CardNotPresentTransaction

export enum AuthorisedPushPaymentFormFieldsKeys {
  AccountType = 'account_type',
  AccountNumber = 'account_number',
  SortCode = 'sort_code',
  Affirmations = 'affirmations',
  AffirmationsValid = 'affirmationsValid',
  Transactions = 'transactions',
  IBAN = 'iban',
  WalletRef = 'wallet_ref',
  IsSuspiciousRequest = 'is_suspicious_request',
}

export enum AccountType {
  IBAN = 'IBAN',
  SCAN = 'SCAN',
  WalletRef = 'WALLET_REF',
  CardNumber = 'CARD_NUMBER',
}

export enum AuthorisedPushPaymentFormFieldTransactionKeys {
  Timestamp = 'timestamp',
  Beneficiary = 'beneficiary',
}

type AuthorisedPushPaymentTransaction = Transaction & {
  beneficiary: string
  description?: string
}

type AuthorisedPushPaymentTransactionsList = Array<AuthorisedPushPaymentTransaction>

type AuthorisedPushPaymentFormValuesFragment = {
  [AuthorisedPushPaymentFormFieldsKeys.Transactions]: AuthorisedPushPaymentTransactionsList
  [AuthorisedPushPaymentFormFieldsKeys.Affirmations]: Array<boolean>
}

export type AuthorisedPushPaymentFormValues = AuthorisedPushPaymentFormValuesFragment &
  (
    | {
        [AuthorisedPushPaymentFormFieldsKeys.AccountType]: AccountType.WalletRef
        [AuthorisedPushPaymentFormFieldsKeys.WalletRef]: string
      }
    | {
        [AuthorisedPushPaymentFormFieldsKeys.AccountType]: AccountType.IBAN
        [AuthorisedPushPaymentFormFieldsKeys.IBAN]: string
      }
    | {
        [AuthorisedPushPaymentFormFieldsKeys.AccountType]: AccountType.SCAN
        [AuthorisedPushPaymentFormFieldsKeys.SortCode]: string
        [AuthorisedPushPaymentFormFieldsKeys.AccountNumber]: string
      }
  )

export type AuthorisedPushPaymentFormSubmitPayload = Omit<
  AuthorisedPushPaymentFormValues,
  'affirmations'
>

export type CerberusSubmitReturn = { reference_number: string }

export type TextInputProps<DataType extends Record<string, any>> =
  UseFieldConfig<DataType> & {
    name: keyof DataType | string
  } & Pick<
      TextFieldProps,
      | 'disabled'
      | 'autoComplete'
      | 'label'
      | 'placeholder'
      | 'inputProps'
      | 'multiline'
      | 'rows'
      | 'fullWidth'
      | 'type'
      | 'helperText'
      | 'title'
      | 'variant'
    >

export type LoginFormValues = {
  email: string
}

export type PrivateRoutePayload = { token?: string }

// admin panel types

export type PartnerEmail = {
  id: string
  email: string
}

export type PartnerEmailList = Array<PartnerEmail>

export type PartnerItem = {
  id: string
  domain_name: string
  absolute_daily_limit: number | null
  is_whole_domain_whitelisted: boolean
  whitelisted_emails: PartnerEmailList
}
export type PartnerItemsList = Array<PartnerItem>

export type SettingsPartnersEndpointResponse = PartnerItemsList

export enum FileTypes {
  Pdf = 'application/pdf',
  Any = '*/*',
}

export type ServerError = {
  detail:
    | string
    | [
        {
          loc: ReadonlyArray<string>
          msg: string
          type: string
        },
      ]
}

export type QueryErrorType = ServerError | Error
export type MutationErrorType = QueryErrorType

export enum RequestForInformationSampleDocumentDocumentType {
  AccountData = 'accountData',
  KYC = 'kyc',
  TransactionHistory = 'transactionHistory',
  AccountBalance = 'accountsBalance',
  TouUpCards = 'topupCards',
  RevolutCards = 'revolutCards',
  DeviceInformation = 'deviceInformation',
}

export type RequestForInformationDocument = {
  id: string
  name: string
  type: RequestForInformationSampleDocumentDocumentType
  end_date?: string
  start_date?: string
}

export type RequestForInformationSampleDocument = Pick<
  RequestForInformationDocument,
  'name' | 'type'
> & {
  is_timeframed: boolean
}

export type Nullable<T> = T | null
export type FileInputRef = RefObject<HTMLInputElement>
