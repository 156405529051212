import { Factory } from 'miragejs'
import {
  UserReport,
  UserReportStatus,
  UserReportType,
} from 'pages/Reports/ReportsTable/types'
import faker from 'faker'
import { formatISO } from 'date-fns'

import { getCurrentDate } from 'utils/date'
import { FactoryMap } from './types'

export const createMockUserReportFactory = () => {
  const reportStatuses = Object.values(UserReportStatus)
  const reportTypes = Object.values(UserReportType)

  return Factory.extend<FactoryMap<UserReport>>({
    id: () => faker.random.uuid(),
    type: () => reportTypes[faker.random.number(reportTypes.length - 1)],
    status: () => reportStatuses[faker.random.number(reportStatuses.length - 1)],
    created_at: () => formatISO(faker.date.past(1, getCurrentDate())),
    reference_id: () => faker.random.number({ min: 1, max: 999999 }).toString(),
    description: () => faker.lorem.sentence(faker.random.number({ min: 0, max: 50 })),
  })
}
