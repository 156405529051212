import React, { FunctionComponent } from 'react'
import { AccordionSummary } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import { useFormState } from 'react-final-form'

import { PartnerItem } from 'utils'
import { TEXT_PartnerCard } from './PartnerCard'
import { DomainNameIcon } from './DomainNameIcon'
import { DomainDailyLimitIcon } from './DomainDailyLimitIcon'
import { DomainWhiteListedIcon } from './DomainWhiteListedIcon'

export const PartnerCardFormHeader: FunctionComponent = () => {
  const {
    values: { domain_name, absolute_daily_limit, is_whole_domain_whitelisted },
  } = useFormState<PartnerItem>()

  return (
    <AccordionSummary
      expandIcon={
        <div title={TEXT_PartnerCard.expandCardButtonTitle}>
          <ExpandMoreRounded />
        </div>
      }
    >
      <DomainNameIcon domain_name={domain_name} />
      <DomainDailyLimitIcon absolute_daily_limit={absolute_daily_limit} />
      <DomainWhiteListedIcon is_whole_domain_whitelisted={is_whole_domain_whitelisted} />
    </AccordionSummary>
  )
}
