import { useQuery } from 'react-query'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { FunctionComponent } from 'react'
import { cond, flow, forEach, get, isError } from 'lodash/fp'

import { Box, Typography } from '@material-ui/core'
import { navigate, RouteComponentProps, useParams } from '@reach/router'

import { Layout, Preloader } from 'components'
import { cerberusRequest } from 'services/Cerberus'
import { redirectToSignIn } from 'services/IDaveAuth'
import { SubmitFormButton } from 'components/buttons'
import { AffirmationsList } from 'components/forms/commonFields'
import { getFullApiRoutePath, setApiRoutePathDynamicKey } from 'utils/url'
import { Nullable, RequestForInformationDocument, useNotifications } from 'utils'
import {
  APIRoutePaths,
  ClientRoutePaths,
  CommonErrorMessages,
  RoutePathsDynamicKeys,
} from 'utils/consts'

import { parseApiCustomError } from 'utils/parseApiCustomError'
import {
  isStatusCustomError,
  isStatusForbidden,
  isStatusNotFound,
  isStatusUnauthorised,
} from 'utils/axiosErrors'
import { ApprovalDecision } from './ApprovalDecision'
import { reviewAffirmations } from './reviewAffirmations'
import {
  RequestForInformationApprovalDecision,
  RequestForInformationReviewFormState,
} from './types'
import { useRequestForInformationReviewSubmitMutation } from './useRequestForInformationReviewSubmitMutation'

export const TEXT_REQUEST_FOR_INFORMATION_REVIEW = {
  confirmButton: 'Confirm',
  header: 'Request for information review',
  documentsForReviewHeader: 'Requested documents',
  afterSubmitText: 'Request has been submitted successfully',
}

export type RequestForInformationReviewQueryResponse = {
  rfi_id: string
  requester: string
  reference: string
  documents: ReadonlyArray<RequestForInformationDocument>
}

const validateRfiReviewForm = (formState: RequestForInformationReviewFormState) => {
  if (formState.decision === RequestForInformationApprovalDecision.Approve) {
    const allAffirmationsAnsweredYes = formState.affirmations.every(
      affirmation => affirmation.value,
    )

    return allAffirmationsAnsweredYes
      ? undefined
      : { [FORM_ERROR]: 'For approval action all questions should be answered "Yes"' }
  }

  const hasFilledRejectionReason =
    formState.rejectionReason && formState.rejectionReason.length

  return hasFilledRejectionReason
    ? undefined
    : { [FORM_ERROR]: 'Please, specify rejection reason' }
}

export const RequestForInformationReview: FunctionComponent<RouteComponentProps> =
  (): Nullable<JSX.Element> => {
    const { rfiId } = useParams()
    const { toastError } = useNotifications()

    const { isLoading, data } = useQuery(
      APIRoutePaths.RequestForInformationReview,
      () => {
        const url = setApiRoutePathDynamicKey(
          APIRoutePaths.RequestForInformationReview,
          RoutePathsDynamicKeys.RequestForInformationId,
          rfiId,
        )

        return cerberusRequest<undefined, RequestForInformationReviewQueryResponse>({
          url,
          method: 'GET',
        })
      },
      {
        retry: false,
        onError: cond([
          [isStatusForbidden, () => navigate(ClientRoutePaths.ForBidden)],
          [
            isStatusUnauthorised,
            () => {
              toastError(CommonErrorMessages.UserNotLoggedIn)
              redirectToSignIn()
            },
          ],
          [isStatusNotFound, () => navigate(ClientRoutePaths.NotFound)],
          [
            isStatusCustomError,
            flow(get('response.data'), parseApiCustomError, forEach(toastError)),
          ],
          [err => isError(err), flow(get('message'), toastError)],
        ]),
      },
    )

    const { mutate: submitRequestForInformationApproval, status: approvalQueryStatus } =
      useRequestForInformationReviewSubmitMutation({ rfiId })

    if (isLoading) return <Preloader />
    if (!data?.data) return null

    if (approvalQueryStatus === 'success') {
      return (
        <Layout>
          <Box
            minHeight="60vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Typography>
                {TEXT_REQUEST_FOR_INFORMATION_REVIEW.afterSubmitText}
              </Typography>
            </Box>
          </Box>
        </Layout>
      )
    }

    let referenceDocumentUrl = getFullApiRoutePath(
      setApiRoutePathDynamicKey(
        APIRoutePaths.RequestForInformationReviewReferenceDocument,
        RoutePathsDynamicKeys.RequestForInformationId,
        rfiId,
      ),
    )

    // show mock pdf when all api data mocked
    if (process.env.REACT_APP_MOCK_SERVER) {
      referenceDocumentUrl = '/sample.pdf'
    }

    return (
      <Layout>
        <Typography variant="h6" component="h2">
          {TEXT_REQUEST_FOR_INFORMATION_REVIEW.header} {`"${rfiId}"`}
        </Typography>
        <Box mt={3}>
          <Typography variant="body1" component="span">
            Requester: {data.data.requester}
          </Typography>
          <div>
            <Typography variant="body1" component="span">
              Reference: {data.data.reference}
            </Typography>
          </div>
        </Box>

        <Box my={4}>
          <Typography variant="h6" component="h3">
            {TEXT_REQUEST_FOR_INFORMATION_REVIEW.documentsForReviewHeader}
          </Typography>
          <Box mt={2}>
            <ul>
              {data.data.documents.map(({ name }) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </Box>
        </Box>

        <Box my={4}>
          <embed src={referenceDocumentUrl} width="100%" height="700" />
        </Box>
        <Form<RequestForInformationReviewFormState>
          initialValues={{ affirmations: reviewAffirmations }}
          mutators={{ ...arrayMutators }}
          onSubmit={formValues => submitRequestForInformationApproval(formValues)}
          validate={validateRfiReviewForm}
        >
          {({ handleSubmit, submitting, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Box my={4}>
                <AffirmationsList name="affirmations" />
              </Box>

              <ApprovalDecision />

              <Box mt={4}>
                <SubmitFormButton
                  disabled={invalid || submitting}
                  text={TEXT_REQUEST_FOR_INFORMATION_REVIEW.confirmButton}
                />
              </Box>
            </form>
          )}
        </Form>
      </Layout>
    )
  }
