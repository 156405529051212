import React, { FunctionComponent } from 'react'
import { EditOutlined } from '@material-ui/icons'

import { TooltipButton, TooltipButtonProps } from 'components/buttons'

export const TEXT_EditButton = {
  title: 'switch to Edit mode',
}

export const EditButton: FunctionComponent<TooltipButtonProps> = props => {
  return (
    <TooltipButton title={TEXT_EditButton.title} {...props}>
      <EditOutlined />
    </TooltipButton>
  )
}
