import React from 'react'
import { Box, Typography } from '@material-ui/core'

export const TEXT_EMPTY_TABLE_WIDGET = 'No reports yet'

export const EmptyTableWidget = (): JSX.Element => (
  <Box display="flex" justifyContent="center" py={4}>
    <Typography variant="h6">🗑 {TEXT_EMPTY_TABLE_WIDGET}</Typography>
  </Box>
)
