import { Model } from 'miragejs'

import { UserReport } from 'pages/Reports/ReportsTable/types'
import { PendingPartner } from 'pages/admin/ManagePartnersTable'

import { PartnerItem, RequestForInformationSampleDocument } from '../types'

export const mockServerModels = {
  report: Model.extend<UserReport | undefined>(undefined),
  partner: Model.extend<PartnerItem | undefined>(undefined),
  pendingPartner: Model.extend<PendingPartner | undefined>(undefined),
  rfiDocument: Model.extend<RequestForInformationSampleDocument | undefined>(undefined),
}
