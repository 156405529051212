import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'

import { getCurrentDate } from 'utils/date'

const currentYear = getCurrentDate().getFullYear()
export const TEXT_COPY_RIGHTS = `© ${currentYear} Revolut`

export const Footer: FunctionComponent = () => (
  <Box>
    <Typography align="center">{TEXT_COPY_RIGHTS}</Typography>
  </Box>
)
