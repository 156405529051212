import React, { FunctionComponent } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { Button } from '@material-ui/core'

import { Transaction } from 'utils'

type AddTransactionProps = {
  fields: FieldArrayRenderProps<Transaction, HTMLButtonElement>['fields']
  initialValue: Transaction
}

const TEXT_ADD_TRANSACTION = 'Add Transaction'

export const AddTransactionButton: FunctionComponent<AddTransactionProps> = ({
  fields,
  initialValue,
}) => (
  <Button variant="contained" onClick={() => fields.push(initialValue)} color="primary">
    {TEXT_ADD_TRANSACTION}
  </Button>
)
