import { useMutation } from 'react-query'
import { flow, forEach, get, isError, cond } from 'lodash/fp'

import { useNotifications } from 'utils'
import { MutationErrorType } from 'utils/types'
import { cerberusRequest } from 'services/Cerberus'
import { redirectToSignIn } from 'services/IDaveAuth'
import { setApiRoutePathDynamicKey } from 'utils/url'
import { APIRoutePaths, CommonErrorMessages, RoutePathsDynamicKeys } from 'utils/consts'

import { parseApiCustomError } from 'utils/parseApiCustomError'
import {
  isStatusCustomError,
  isStatusForbidden,
  isStatusUnauthorised,
} from 'utils/axiosErrors'

import {
  RequestForInformationApprovalDecision,
  RequestForInformationReviewFormState,
} from './types'

type useRequestForInformationReviewSubmitMutationVariables = {
  rfiId: string
}

export const useRequestForInformationReviewSubmitMutation = ({
  rfiId,
}: useRequestForInformationReviewSubmitMutationVariables) => {
  const { toastError } = useNotifications()

  return useMutation<any, MutationErrorType, RequestForInformationReviewFormState>(
    ({ decision, rejectionReason }) => {
      const path =
        decision === RequestForInformationApprovalDecision.Approve
          ? APIRoutePaths.RequestForInformationApproveRequest
          : APIRoutePaths.RequestForInformationRejectRequest

      const url = setApiRoutePathDynamicKey(
        path,
        RoutePathsDynamicKeys.RequestForInformationId,
        rfiId,
      )

      return cerberusRequest({
        url,
        data:
          decision === RequestForInformationApprovalDecision.Reject
            ? { reject_reason: rejectionReason }
            : undefined,
      })
    },
    {
      onError: cond([
        [
          isStatusCustomError,
          flow(get('response.data'), parseApiCustomError, forEach(toastError)),
        ],
        [isStatusForbidden, () => toastError(CommonErrorMessages.HasNoAccess)],
        [
          isStatusUnauthorised,
          () => {
            toastError(CommonErrorMessages.UserNotLoggedIn)
            redirectToSignIn()
          },
        ],
        [isError, flow(get('message'), toastError)],
      ]),
    },
  )
}
