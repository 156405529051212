import React from 'react'
import {
  AffirmationRadioItem,
  AffirmationsListType,
  AffirmationText,
} from 'components/forms/commonFields'

export const reviewAffirmations: AffirmationsListType = [
  {
    value: false,
    id: 'is_request_valid',
    text: 'I have confirmed that this is a valid request for information',
    render: props => <AffirmationRadioItem<AffirmationText> {...props} />,
  },
  {
    value: false,
    id: 'is_attached_docs_correct',
    text: 'I have confirmed the attached documents are correct',
    render: props => <AffirmationRadioItem {...props} />,
  },
]
