import React, { FunctionComponent } from 'react'
import { CancelOutlined } from '@material-ui/icons'

import { ConfirmationButton, ConfirmationButtonProps } from 'components/buttons'

const TEXT_CancelButton = {
  title: 'delete',
}

export const CancelButton: FunctionComponent<ConfirmationButtonProps> = props => (
  <ConfirmationButton title={TEXT_CancelButton.title} {...props}>
    <CancelOutlined />
  </ConfirmationButton>
)
