/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { Grid, Typography } from '@material-ui/core'

import { SelectField, TextInput } from 'components/forms/commonFields'
import { AddButton } from './addButton'

const emptyCondition = { key: 1, value: '' }
const initialValue = [emptyCondition]

const automationConditionsOptions = [
  {
    label: 'Exclude',
    value: 0,
  },
  {
    label: 'Include',
    value: 1,
  },
  {
    label: 'Email:from',
    value: 2,
  },
  {
    label: 'Email:to',
    value: 3,
  },
]

export const TEXT_AUTOMATION_CONDITIONS = {
  addCondition: 'Add condition',
  heading: 'Conditions',
}

export const AutomationConditions = (): JSX.Element => {
  const { fields } = useFieldArray('conditions', {
    initialValue,
  })

  return (
    <>
      <Typography variant="h6" component="h3">
        {TEXT_AUTOMATION_CONDITIONS.heading}
      </Typography>
      <AddButton
        onClick={() => fields.push(emptyCondition)}
        text={TEXT_AUTOMATION_CONDITIONS.addCondition}
      />
      {fields.map(fieldName => (
        <Grid key={fieldName} container spacing={6}>
          <Grid item>
            <SelectField
              key={fieldName}
              name={`${fieldName}.key`}
              options={automationConditionsOptions}
            />
          </Grid>
          <Grid item>
            <TextInput name={`${fieldName}.value`} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
