/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { Box } from '@material-ui/core'

import { SubmitFormButton } from 'components/buttons'

import { AutomationActions, automationsAffirmations } from './AutomationActions'
import { AutomationVariables } from './AutomationVariables'
import { AutomationConditions } from './AutomationConditions'
import { TestReference } from './TestReference'

export const AutomationForm = (): JSX.Element => (
  <Form
    initialValues={{
      automationActions: automationsAffirmations,
    }}
    mutators={{ ...arrayMutators }}
    onSubmit={formValues =>
      // eslint-disable-next-line no-console
      console.log(`------ automation form submit ------`, formValues)
    }
  >
    {({ handleSubmit, invalid, submitting, pristine }) => (
      <form onSubmit={handleSubmit}>
        <AutomationConditions />
        <Box my={12}>
          <AutomationVariables />
        </Box>
        <AutomationActions />
        <Box my={12}>
          <TestReference />
        </Box>

        <SubmitFormButton disabled={pristine || invalid || submitting} />
      </form>
    )}
  </Form>
)
