import React from 'react'

import { AuthorisedPushPaymentFormFieldsKeys } from 'utils'

import { useAllSelectedValidation } from 'utils/formValidators'
import { AffirmationsList } from 'components/forms/commonFields'

export const AuthorisedPushPaymentFormAffirmations = (): JSX.Element => {
  useAllSelectedValidation(AuthorisedPushPaymentFormFieldsKeys.Affirmations)

  return <AffirmationsList name={AuthorisedPushPaymentFormFieldsKeys.Affirmations} />
}
