/* istanbul ignore file */ // TODO: remove after POC approval https://revolut.atlassian.net/browse/STEE-768
import React from 'react'
import { Typography } from '@material-ui/core'

import {
  AffirmationCheckBoxItem,
  AffirmationsList,
  AffirmationsListType,
  SelectField,
  SelectFieldOptions,
} from 'components/forms/commonFields'

export const TEXT_AUTOMATION_ACTIONS = {
  heading: 'Actions',
}

const statusOptions: SelectFieldOptions = [
  {
    label: 'pending',
    value: 1,
  },
  {
    label: 'solved',
    value: 2,
  },
]

export const automationsAffirmations: AffirmationsListType = [
  {
    id: 'aa1',
    text: 'Lock account',
    value: false,
    render: props => <AffirmationCheckBoxItem {...props} />,
  },
  {
    id: 'aa2',
    text: 'Leave BackOfficeNote',
    value: false,
    render: props => <AffirmationCheckBoxItem {...props} />,
  },
  {
    id: 'aa3',
    text: 'Raise UAR',
    value: false,
    render: props => <AffirmationCheckBoxItem {...props} />,
  },
  {
    id: 'aa4',
    text: 'Change ticket status',
    value: false,
    render: props => (
      <AffirmationCheckBoxItem {...props}>
        {() => (
          <SelectField
            name={`${props.name}.status`}
            label="ticket status"
            options={statusOptions}
          />
        )}
      </AffirmationCheckBoxItem>
    ),
  },
]

export const AutomationActions = (): JSX.Element => {
  return (
    <>
      <Typography variant="h6" component="h3">
        {TEXT_AUTOMATION_ACTIONS.heading}
      </Typography>

      <AffirmationsList name="automationActions" />
    </>
  )
}
