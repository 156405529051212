import React from 'react'
import { AppBar, Box, Grid, Toolbar } from '@material-ui/core'
import { useLocation } from '@reach/router'

import { Logo } from 'components/Logo'
import { AdminClientRoutePaths, ClientRoutePaths } from 'utils/consts'
import { makeStyles } from '@material-ui/core/styles'
import { UserActionBox } from './UserActionBox/UserActionBox'

const useStyles = makeStyles(theme => ({
  userIdentityWithActions: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

export const Header = (): JSX.Element => {
  const styles = useStyles()
  const { pathname } = useLocation()

  const isUserActionBoxVisible =
    pathname === ClientRoutePaths.Index ||
    pathname === AdminClientRoutePaths.Root ||
    pathname === ClientRoutePaths.Reports

  return (
    <Box mb={6}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Logo maxWidth="7rem" />
            </Grid>
            <Grid item>
              <Box className={styles.userIdentityWithActions}>
                {isUserActionBoxVisible && <UserActionBox />}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
