import { createServer } from 'miragejs'

import { mockServerModels } from './models'
import { mockServerSeeds } from './mockServerSeeds'
import { mockServerModelFactories } from './modelFactories'
import { endpointsHandlers } from './endpointsHandlers'

export * from './types'

export const createMockServer = (environment: 'development' | 'test' = 'test') => {
  const mockServer = createServer<
    typeof mockServerModels,
    typeof mockServerModelFactories
  >({
    environment,
    seeds: mockServerSeeds,
    models: mockServerModels,
    factories: mockServerModelFactories,
  })

  endpointsHandlers.forEach(handler => handler(mockServer))

  return mockServer
}
